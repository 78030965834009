import { AppThunk } from "../store/configureStore";
import type { Connection } from "penpal";
import { addError } from "../store/reducers/errors";
import { resetState } from "../store/reducers/coredata";
import {
  IColumnHookInput,
  IColumnHookOutput,
  IRowHookInput,
  IRowHookOutputInternal,
  IBeforeFinishOutput,
  EBackendSyncMode,
  EStepHook,
  IParentConnectionMethods,
} from "../interfaces";
import { selectResultMetadata } from "../helpers/Hooks";
import { objectToFile } from "../helpers/CoreDataHelpers";
import { uploadCleanedFileHelper, getAPIClient } from "../helpers/APIHelpers";
import {
  DEVELOPMENT_MODE_HEADLESS_LIMIT,
  DEVELOPMENT_MODE_RESULT_ROW_LIMIT,
} from "../constants/constants";
import {
  HandleUploadHookFn,
  HandleReviewHookFn,
  HandleReviewPostHooksHookFn,
  HandleBeforeFinishCallbackFn,
  runUploadStepHook,
  runReviewStepHook,
  runReviewStepPostHooksHook,
  runBeforeFinishCallback,
  runReviewStepPreSubmitHook,
  HandleReviewPreSubmitHookFn,
} from "./hooks";
import {
  generateErrorsForExportWithValues,
  generateResultData,
  sendResultMetadata,
} from "./result_data";
import { FullDataWithMeta } from "./data_actions";
import { benchmarkStart, benchmarkEnd } from "../util/benchmark";

type ParentConnection = Connection<IParentConnectionMethods> | null;

export const handleColumnHooks = (
  connection: ParentConnection,
  fieldName: string,
  data: IColumnHookInput[]
): AppThunk<Promise<IColumnHookOutput[]>> => {
  return async (dispatch) => {
    if (!connection) {
      dispatch(addSdkConnectionError());
      return data;
    }

    const parent = await connection.promise;
    const hookOutput = await parent.handleColumnHooks(fieldName, data);
    return hookOutput ?? data;
  };
};

export const handleRowHooks = (
  connection: ParentConnection,
  data: IRowHookInput[],
  mode: "init" | "update"
): AppThunk<Promise<IRowHookOutputInternal[]>> => {
  return async (dispatch) => {
    if (!connection) {
      dispatch(addSdkConnectionError());
      return data;
    }

    const parent = await connection.promise;
    const hookOutput = await parent.handleRowHooks(data, mode);
    return hookOutput ?? data;
  };
};

export const handleRowDeleteHooks = (
  connection: ParentConnection,
  data: IRowHookInput[]
): AppThunk<Promise<void>> => {
  return async (dispatch) => {
    if (!connection) {
      dispatch(addSdkConnectionError());
      return;
    }

    const parent = await connection.promise;
    if (typeof parent.handleRowDeleteHooks === "function") {
      await parent.handleRowDeleteHooks(data);
    }
  };
};

export const handleBeforeFinish = (
  connection: ParentConnection,
  fullData: FullDataWithMeta
): AppThunk<Promise<IBeforeFinishOutput | null>> => {
  return async (dispatch) => {
    if (!connection) {
      dispatch(addSdkConnectionError());
      return null;
    }

    const parent = await connection.promise;
    if (typeof parent.handleBeforeFinishCallback !== "function") return null;

    return await dispatch(
      runBeforeFinishCallback(
        fullData,
        parent.handleBeforeFinishCallback as HandleBeforeFinishCallbackFn
      )
    );
  };
};

// note: columnMappingCacheData is null if there are no headers
export const handleResults = (
  connection: ParentConnection,
  fullData: FullDataWithMeta
): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    if (!connection) {
      dispatch(addSdkConnectionError());
      return;
    }
    benchmarkStart("handleResults");

    const state = getState();
    const { backendSyncMode, importerMode, backendCapabilities } =
      state.settings;
    const { headlessImportId } = state.coredata;

    const resultData = dispatch(generateResultData(fullData));

    // filename will eventually be <uuid>-cleaned-data.csv in the backend via
    // uploadFileUploadHelper
    let file: File | null;
    if (backendCapabilities.accept_json_results) {
      file = objectToFile(resultData, "cleaned-data.json", "json", true);
    } else {
      file = objectToFile(resultData, "cleaned-data.csv", "csv");
    }

    let cleanedDataUploadId = null;
    const syncFullData = backendSyncMode === EBackendSyncMode.FULL_DATA;
    if (file) {
      // if backendSync is allowed, upload the cleaned file
      if (syncFullData) {
        const { uploadId } = await dispatch(
          uploadCleanedFileHelper(file, resultData.length)
        );
        cleanedDataUploadId = uploadId;
      }
    }

    await dispatch(sendResultMetadata(resultData.length));

    // the ID refers to a CleanedData object, which only happens when
    // backendSync is set to true
    const uploadMetadata = selectResultMetadata(state, cleanedDataUploadId);

    if (headlessImportId) {
      const api = getAPIClient(state);
      await api.updateHeadlessImport(headlessImportId, {
        status: "SUCCESSFUL",
        result_metadata: uploadMetadata,
      });
    }

    let trimmedData = resultData;

    if (importerMode !== "PRODUCTION") {
      trimmedData = resultData.slice(
        0,
        headlessImportId
          ? DEVELOPMENT_MODE_HEADLESS_LIMIT
          : DEVELOPMENT_MODE_RESULT_ROW_LIMIT
      );
    }

    if (backendCapabilities.allow_js_results !== false) {
      const errorsWithValues = dispatch(
        generateErrorsForExportWithValues(fullData)
      );
      const parent = await connection.promise;
      const metadataWithErrorValues = {
        ...uploadMetadata,
        errors: errorsWithValues,
      };
      await parent.handleResults(trimmedData, metadataWithErrorValues);
    }
    benchmarkEnd("handleResults");
  };
};

export const handleStepHook = (
  connection: ParentConnection,
  step: EStepHook
): AppThunk<Promise<void>> => {
  return async (dispatch) => {
    if (!connection) {
      dispatch(addSdkConnectionError());
      return;
    }

    const parent = await connection.promise;
    if (typeof parent.handleStepHook !== "function") return;

    switch (step) {
      case EStepHook.UPLOAD_STEP:
        await dispatch(
          runUploadStepHook(parent.handleStepHook as HandleUploadHookFn)
        );
        return;
      case EStepHook.REVIEW_STEP:
        await dispatch(
          runReviewStepHook(parent.handleStepHook as HandleReviewHookFn)
        );
        return;
      case EStepHook.REVIEW_STEP_POST_HOOKS:
        await dispatch(
          runReviewStepPostHooksHook(
            parent.handleStepHook as HandleReviewPostHooksHookFn
          )
        );
        return;
      case EStepHook.REVIEW_STEP_PRE_SUBMIT:
        await dispatch(
          runReviewStepPreSubmitHook(
            parent.handleStepHook as HandleReviewPreSubmitHookFn
          )
        );
    }
  };
};

export const handleCloseModal = (
  connection: ParentConnection
): AppThunk<Promise<void>> => {
  return async (dispatch) => {
    if (!connection) {
      dispatch(addSdkConnectionError());
      return;
    }

    const parent = await connection.promise;
    await parent.handleCloseModal();
    dispatch(resetState());
  };
};

export const handleCancelModal = (
  connection: ParentConnection
): AppThunk<Promise<void>> => {
  return async (dispatch) => {
    if (!connection) {
      dispatch(addSdkConnectionError());
      return;
    }

    const parent = await connection.promise;
    await parent.handleCancel();
    dispatch(resetState());
  };
};

const addSdkConnectionError = (): AppThunk => {
  return (dispatch) => {
    dispatch(
      addError({
        type: "developer",
        code: "E_SDK_CONNECTION_ERROR",
        messageKey: "alert.parentConnectionError",
      })
    );
  };
};
