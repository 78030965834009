import {
  IData,
  IDeveloperSelectOption,
  ITableMessages,
  IWorksheet,
  IRowToAdd,
} from "../../interfaces";
import { createSlice, createSelector } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { setFromDeveloperSettings } from "./settings";
import { RootState } from "../reducers";
import {
  aiMatchColumns,
  aiOrFuzzyMatchSelectOptions,
} from "../../thunks/column_matching";
import { SelectOptionOverride } from "../../thunks/data_actions";
import { SelectField } from "../../fields/select";
import Fuse from "fuse.js";

// Top level map is colIdx -> map of rows that have overrides
// Next level is rowIdx -> overrides
// Overrides is label -> value
export type SelectOptionOverrides = Map<
  number,
  Map<number, Map<string, string>>
>;

export type IRowToAddWithId = IRowToAdd & { rowId: string };

interface ICoreDataReduxState {
  data: IData;
  // originalFilename will be null if data came from
  // manual entry or initialData
  originalFilename: string | null;
  // if the uploaded file is an excel file, an array of sheets
  sheets: IWorksheet[] | null;
  // if the uploaded file is an excel file, the sheet ID being used for this import
  selectedSheetId: string | null;
  rawDataRowLength: number;
  rawDataHeaderRow: number | null;
  headers: string[] | null;
  encoding: string;
  // Cells in `row,column` form that had an error when doing
  // the field transform on the data
  transformErrorCells: Set<string>;
  // Map of matches for each unique value of each column
  matchResults: Map<
    number,
    { [x: string]: any } // Fuse.FuseResult<IDeveloperSelectOption> }
  >;
  tableMessages: ITableMessages;
  // Map of stringified array of options to label->value map, which we most often use
  selectOptionOverrideSets: Map<string, Map<string, string>>;
  selectOptionOverrides: SelectOptionOverrides;
  // Raw upload id
  uploadId: string | null;
  // Processed data id
  processedDataId: string | null;
  // The HeadlessImport id, if this is a headless import
  headlessImportId: string | null;
  // count of registered webhooks
  numRegisteredColHooks: number;
  numRegisteredRowHooks: number;
  numRegisteredRowDeleteHooks: number;
  aiColMatchStatus: "idle" | "pending" | "fulfilled";
  aiMatchSelectOptionStatus: Map<number, "idle" | "pending" | "fulfilled">;
  // An array of field keys that the user has opted to add empty columns for
  initialized: boolean;
  rowsToAdd: IRowToAddWithId[];
  rowsToDelete: string[];
  beforeFinishMessage: string | null;
  submitConfirmationOverride?: {
    messageHTML: string;
    options?: { submitButtonText?: string; cancelButtonText?: string };
  };
}

const initialState: ICoreDataReduxState = {
  data: {
    previewData: [[]],
    rawPreviewData: [[]],
    file: null,
    uploadType: "FILE",
    valCountsInColumn: null,
  },
  encoding: "UTF-8",
  originalFilename: null,
  sheets: null,
  selectedSheetId: null,
  rawDataRowLength: 0,
  rawDataHeaderRow: null,
  headers: null,
  transformErrorCells: new Set(),
  matchResults: new Map(),
  tableMessages: new Map(),
  selectOptionOverrideSets: new Map(),
  selectOptionOverrides: new Map(),
  uploadId: null,
  processedDataId: null,
  headlessImportId: null,
  numRegisteredColHooks: 0,
  numRegisteredRowHooks: 0,
  numRegisteredRowDeleteHooks: 0,
  aiColMatchStatus: "idle",
  aiMatchSelectOptionStatus: new Map(),
  initialized: false,
  rowsToAdd: [],
  rowsToDelete: [],
  beforeFinishMessage: null,
};

const coredataSlice = createSlice({
  name: "coredata",
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },

    resetStatePreservingInit: (state) => {
      return {
        ...initialState,
        numRegisteredColHooks: state.numRegisteredColHooks,
        numRegisteredRowHooks: state.numRegisteredRowHooks,
        numRegisteredRowDeleteHooks: state.numRegisteredRowDeleteHooks,
      };
    },

    resetStatePreReview: (state) => {
      state.tableMessages = initialState.tableMessages;
      state.transformErrorCells = initialState.transformErrorCells;
      state.selectOptionOverrideSets = initialState.selectOptionOverrideSets;
      state.selectOptionOverrides = initialState.selectOptionOverrides;
    },

    setEncoding: (state, action: PayloadAction<string>) => {
      state.encoding = action.payload;
    },

    setOriginalFilename: (state, action: PayloadAction<string | null>) => {
      state.originalFilename = action.payload;
    },

    setHeaders: {
      prepare: (headers: string[] | null, rawDataHeaderRow?: number | null) => {
        return { payload: { headers, rawDataHeaderRow } };
      },
      reducer: (
        state,
        action: PayloadAction<{
          headers: string[] | null;
          rawDataHeaderRow: number | null | undefined;
        }>
      ) => {
        state.headers = action.payload.headers;
        if (action.payload.rawDataHeaderRow !== undefined)
          state.rawDataHeaderRow = action.payload.rawDataHeaderRow;
      },
    },

    setData: (state, action: PayloadAction<Partial<IData>>) => {
      state.data = { ...state.data, ...action.payload };
    },

    setRawDataRowLength: (state, action: PayloadAction<number>) => {
      state.rawDataRowLength = action.payload;
    },

    addMatchResult: (
      state,
      action: PayloadAction<{
        columnIndex: number;
        uniqueColumnValue: string;
        result: Fuse.FuseResult<IDeveloperSelectOption>;
      }>
    ) => {
      const { columnIndex, result, uniqueColumnValue } = action.payload;

      if (!state.matchResults.has(columnIndex)) {
        state.matchResults.set(columnIndex, {
          [uniqueColumnValue]: result,
        });
      } else {
        state.matchResults.get(columnIndex)![uniqueColumnValue] = result;
      }
    },

    setTableMessages: (state, action: PayloadAction<ITableMessages>) => {
      state.tableMessages = action.payload;
    },

    setNewSelectOptionOverrideSets: (
      state,
      action: PayloadAction<Map<string, Map<string, string>>>
    ) => {
      state.selectOptionOverrideSets = action.payload;
    },

    setSelectOptionOverrides: (
      state,
      action: PayloadAction<SelectOptionOverrides>
    ) => {
      state.selectOptionOverrides = action.payload;
    },

    updateSelectOptionOverrides: (
      state,
      action: PayloadAction<SelectOptionOverride[]>
    ) => {
      const overridesByCol = action.payload.reduce(
        (byCol, [rowIdx, colIdx, selectOptions]) => {
          byCol[colIdx] ??= [];
          byCol[colIdx].push([rowIdx, selectOptions]);
          return byCol;
        },
        {} as Record<number, [number, IDeveloperSelectOption[]][]>
      );

      for (const [colIdxStr, optionsSets] of Object.entries(overridesByCol)) {
        const colIdx = parseInt(colIdxStr);
        if (!state.selectOptionOverrides.has(colIdx)) {
          state.selectOptionOverrides.set(colIdx, new Map());
        }

        for (let [rowIdx, selectOptions] of optionsSets) {
          selectOptions = selectOptions.map(SelectField.normalizeOption);
          const optionsString = JSON.stringify(selectOptions);

          let optionsSet: Map<string, string>;

          // check to see if we have a 'master copy' of these options
          if (state.selectOptionOverrideSets.has(optionsString)) {
            optionsSet = state.selectOptionOverrideSets.get(optionsString)!;
          } else {
            optionsSet = new Map<string, string>(
              selectOptions.map(({ label, value }) => [label, value])
            );
            state.selectOptionOverrideSets.set(optionsString, optionsSet);
          }

          state.selectOptionOverrides.get(colIdx)!.set(rowIdx, optionsSet);
        }
      }
    },

    setUploadId: (state, action: PayloadAction<string | null>) => {
      state.uploadId = action.payload;
    },

    setProcessedDataId: (state, action: PayloadAction<string>) => {
      state.processedDataId = action.payload;
    },

    setHeadlessImportId: (state, action: PayloadAction<string>) => {
      state.headlessImportId = action.payload;
    },

    setRawDataHeaderRow: (state, action: PayloadAction<number | null>) => {
      state.rawDataHeaderRow = action.payload;
    },

    setSheets: (state, action: PayloadAction<IWorksheet[] | null>) => {
      state.sheets = action.payload;
    },

    setSelectedSheet: (state, action: PayloadAction<string | null>) => {
      state.selectedSheetId = action.payload;
    },

    setNumRegisteredColHooks: (state, action: PayloadAction<number>) => {
      state.numRegisteredColHooks = action.payload;
    },

    setNumRegisteredRowHooks: (state, action: PayloadAction<number>) => {
      state.numRegisteredRowHooks = action.payload;
    },

    setNumRegisteredRowDeleteHooks: (state, action: PayloadAction<number>) => {
      state.numRegisteredRowDeleteHooks = action.payload;
    },

    setCellTransformErrors: (state, action: PayloadAction<Set<string>>) => {
      state.transformErrorCells = action.payload;
    },

    addCellTransformError: (state, action: PayloadAction<string>) => {
      state.transformErrorCells.add(action.payload);
    },

    removeCellTransformError: (state, action: PayloadAction<string>) => {
      state.transformErrorCells.delete(action.payload);
    },

    setInitialized: (state) => {
      state.initialized = true;
    },

    resetPreviewData: (state) => {
      state.data.previewData = state.data.rawPreviewData;
    },

    enqueueAddRows: (state, action: PayloadAction<IRowToAddWithId[]>) => {
      state.rowsToAdd = [...state.rowsToAdd, ...action.payload];
    },

    clearAddRowQueue: (state) => {
      state.rowsToAdd = [];
    },

    enqueueRemoveRows: (state, action: PayloadAction<string[]>) => {
      state.rowsToDelete = [...state.rowsToDelete, ...action.payload];
    },

    clearRemoveRowQueue: (state) => {
      state.rowsToDelete = [];
    },

    setBeforeFinishMessage: (state, action: PayloadAction<string | null>) => {
      state.beforeFinishMessage = action.payload;
    },
    setSubmitConfirmationOverride: (
      state,
      action: PayloadAction<{
        messageHTML: string;
        options?: { submitButtonText?: string; cancelButtonText?: string };
      }>
    ) => {
      state.submitConfirmationOverride = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setFromDeveloperSettings, (state, action) => {
      if (action.payload.matchingStep.headerRowOverride !== null) {
        state.rawDataHeaderRow = action.payload.matchingStep.headerRowOverride;
      }
    });
    builder.addCase(aiMatchColumns.fulfilled, (state, _action) => {
      state.aiColMatchStatus = "fulfilled";
    });
    builder.addCase(aiMatchColumns.pending, (state, _action) => {
      state.aiColMatchStatus = "pending";
    });
    builder.addCase(aiOrFuzzyMatchSelectOptions.fulfilled, (state, action) => {
      state.aiMatchSelectOptionStatus = new Map([
        ...state.aiMatchSelectOptionStatus,
      ]);
      state.aiMatchSelectOptionStatus.set(action.meta.arg, "fulfilled");
    });
    builder.addCase(aiOrFuzzyMatchSelectOptions.pending, (state, action) => {
      state.aiMatchSelectOptionStatus = new Map([
        ...state.aiMatchSelectOptionStatus,
      ]);
      state.aiMatchSelectOptionStatus.set(action.meta.arg, "pending");
    });
  },
});

export const selectHasEmptyHeaders = (state: ICoreDataReduxState): boolean =>
  state.headers ? state.headers.every((header) => !header) : true;

export const selectRowsWithErrors = (
  state: ICoreDataReduxState
): Set<number> => {
  const errorRows = new Set<number>();
  state.tableMessages.forEach((rowMessages, rowIndex) => {
    if (
      Array.from(rowMessages.values()).some((messages) =>
        messages.some((message) => message.level === "error")
      )
    ) {
      errorRows.add(rowIndex);
    }
  });

  return errorRows;
};

export const selectHasValidationErrors = (state: RootState): boolean => {
  for (const rowMessages of state.coredata.tableMessages.values()) {
    if (
      Array.from(rowMessages.values()).some((messages) =>
        messages.some((m) => m.level === "error")
      )
    )
      return true;
  }
  return false;
};

export const selectHasInitialData = (state: RootState): boolean => {
  const { uploadType } = state.coredata.data;
  return (
    uploadType === "INITIAL_DATA_MAPPED" ||
    uploadType === "INITIAL_DATA_UNMAPPED"
  );
};

export const selectUniqueValsInColumn = (
  data: IData
): Map<number, Set<string>> => {
  const valueCountMap = data.valCountsInColumn;
  const uniqValMap = new Map<number, Set<string>>();
  if (valueCountMap !== null) {
    valueCountMap.forEach((valueCounts, colIdx) =>
      uniqValMap.set(colIdx, new Set(valueCounts.keys()))
    );
  }
  return uniqValMap;
};

export const selectColErrors = createSelector(
  (state: RootState) => state.coredata.tableMessages,
  (tableMessages): Map<number, number[]> => {
    const errors = new Map<number, number[]>();

    tableMessages.forEach((rowMessages, rowIndex) => {
      rowMessages.forEach((messages, colIndex) => {
        if (messages.some((message) => message.level === "error")) {
          if (!errors.has(colIndex)) {
            errors.set(colIndex, [rowIndex]);
          } else {
            errors.get(colIndex)!.push(rowIndex);
          }
        }
      });
    });

    return errors;
  }
);

export const selectRawRowWidth = (
  columnMapping: Map<number, unknown>
): number => {
  if (columnMapping.size === 0) return 0;
  return Math.max(...columnMapping.keys()) + 1;
};

export const {
  resetState,
  resetStatePreservingInit,
  resetStatePreReview,
  setEncoding,
  setOriginalFilename,
  setHeaders,
  setRawDataHeaderRow,
  setRawDataRowLength,
  setData,
  addMatchResult,
  setNewSelectOptionOverrideSets,
  setSelectOptionOverrides,
  updateSelectOptionOverrides,
  setTableMessages,
  setUploadId,
  setProcessedDataId,
  setHeadlessImportId,
  setSheets,
  setSelectedSheet,
  setNumRegisteredColHooks,
  setNumRegisteredRowHooks,
  setNumRegisteredRowDeleteHooks,
  setCellTransformErrors,
  addCellTransformError,
  removeCellTransformError,
  setInitialized,
  resetPreviewData,
  enqueueAddRows,
  clearAddRowQueue,
  enqueueRemoveRows,
  clearRemoveRowQueue,
  setBeforeFinishMessage,
  setSubmitConfirmationOverride,
} = coredataSlice.actions;

export default coredataSlice.reducer;
