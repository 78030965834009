import { IDeveloperField, IPositionSpec } from "../interfaces";
import { AppThunk } from "../store/configureStore";
import { FullDataWithMeta, DataThunk } from "./data_actions";
import { DeveloperFieldSchema } from "../helpers/schemas/fields";
import { addError } from "../store/reducers/errors";
import { fromZodError } from "zod-validation-error";
import {
  setColumnMapping,
  registerVirtualField,
  setPartialFieldState,
  selectInvertedColumnMapping,
} from "../store/reducers/fields";
import { fieldFromDeveloperField } from "../fields";
import { setData, setRawDataRowLength } from "../store/reducers/coredata";

export const addVirtualFieldFromDeveloper = (
  field: IDeveloperField,
  position: IPositionSpec | undefined
): AppThunk => {
  return (dispatch) => {
    const parseResult = DeveloperFieldSchema.safeParse(field);
    if (!parseResult.success) {
      dispatch(
        addError({
          type: "developer",
          code: "E_INVALID_FIELDS",
          message: fromZodError(parseResult.error, {
            prefix: "Invalid virtual field",
          }).message,
        })
      );
      return;
    }
    dispatch(registerVirtualField(parseResult.data, position));
  };
};

export const addVirtualFieldsToMapping = (
  data: FullDataWithMeta
): DataThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      selectFieldMapping,
      fieldSpecs,
      virtualFields,
      columnMapping,
      ignoredColumns,
    } = state.fields;
    let {
      rawDataRowLength,
      data: { valCountsInColumn },
    } = state.coredata;

    let newColumnMapping = new Map(columnMapping);
    const newFieldSpecs = new Map(fieldSpecs);
    let newSelectFieldMapping = new Map(selectFieldMapping);
    let newValCountsInColumns = new Map(valCountsInColumn);
    let newIgnoredColumns = new Set(ignoredColumns);

    virtualFields.forEach(({ field, position }) => {
      let newColMappingIdx: number;

      if (position) {
        // if the user has specified a position to put the new field,
        // first we find the position of the field it is referencing
        const refKey = "before" in position ? position.before : position.after;

        const refMappingIdx = [...newColumnMapping.entries()].find(
          ([_index, mapping]) => mapping.key === refKey
        )?.[0];

        if (refMappingIdx !== undefined) {
          newColMappingIdx =
            "before" in position ? refMappingIdx : refMappingIdx + 1;
        } else {
          newColMappingIdx = rawDataRowLength;
        }
      } else {
        // if no position was specified, just add it to the end (before meta)
        newColMappingIdx = rawDataRowLength;
      }
      rawDataRowLength++;

      // add to field specs
      newFieldSpecs.set(field.key, field);

      // shift ignored columns
      newIgnoredColumns = shiftSet(ignoredColumns, newColMappingIdx);

      // we need to shift all of the columns after the added column up 1
      newColumnMapping = shiftMap(newColumnMapping, newColMappingIdx);
      newSelectFieldMapping = shiftMap(newSelectFieldMapping, newColMappingIdx);
      newValCountsInColumns = shiftMap(newValCountsInColumns, newColMappingIdx);

      // then add the virtual field now that we've made room
      newColumnMapping.set(newColMappingIdx, {
        key: field.key,
        matchType: "VIRTUAL",
      });

      const fieldInstance = fieldFromDeveloperField(field, undefined);
      // and add the new empty column to the data
      for (let rowIdx = 0; rowIdx < data.length; rowIdx++) {
        data[rowIdx].splice(
          newColMappingIdx,
          0,
          fieldInstance.getInitialValue()
        );
      }
    });

    dispatch(
      setPartialFieldState({
        fieldSpecs: newFieldSpecs,
        columnMapping: newColumnMapping,
        selectFieldMapping: newSelectFieldMapping,
        ignoredColumns: newIgnoredColumns,
      })
    );
    dispatch(setRawDataRowLength(rawDataRowLength));
    dispatch(setData({ valCountsInColumn: newValCountsInColumns }));
    return data;
  };
};

export const addHiddenFieldsToMapping = (data: FullDataWithMeta): DataThunk => {
  return (dispatch, getState) => {
    const { fields, coredata } = getState();
    const { columnMapping, fieldSpecs } = fields;
    let { rawDataRowLength } = coredata;
    const newColumnMapping = new Map(columnMapping);
    const mappedKeysToColumns = selectInvertedColumnMapping(fields);

    fieldSpecs.forEach((field) => {
      // if it's already mapped (because it was in initialData) don't remap it
      if (field.hidden && !mappedKeysToColumns.has(field.key)) {
        // add hidden field to the end of the data
        newColumnMapping.set(rawDataRowLength++, {
          key: field.key,
          matchType: "VIRTUAL",
        });

        const fieldInstance = fieldFromDeveloperField(field, undefined);
        for (let rowIdx = 0; rowIdx < data.length; rowIdx++) {
          // and add the new empty column to the data, at the end of the row before row metadata
          data[rowIdx].splice(-1, 0, fieldInstance.getInitialValue());
        }
      }
    });

    dispatch(setRawDataRowLength(rawDataRowLength));
    dispatch(setColumnMapping(newColumnMapping));
    return data;
  };
};

export const addEmptyFieldsToMapping = (data: FullDataWithMeta): DataThunk => {
  return (dispatch, getState) => {
    const { fields, coredata } = getState();
    const { columnMapping, addedEmptyFields, fieldSpecs } = fields;
    let { rawDataRowLength } = coredata;
    const newColumnMapping = new Map(columnMapping);

    addedEmptyFields.forEach((key) => {
      // add to the end
      newColumnMapping.set(rawDataRowLength++, { key, matchType: "VIRTUAL" });

      const spec = fieldSpecs.get(key)!;
      const fieldInstance = fieldFromDeveloperField(spec, undefined);
      // and add the new empty column to the data
      for (let rowIdx = 0; rowIdx < data.length; rowIdx++) {
        data[rowIdx].splice(-1, 0, fieldInstance.getInitialValue());
      }
    });

    dispatch(setRawDataRowLength(rawDataRowLength));
    dispatch(setColumnMapping(newColumnMapping));
    return data;
  };
};

export const finalizeMapping = (data: FullDataWithMeta): DataThunk => {
  return (dispatch) => {
    data = dispatch(addEmptyFieldsToMapping(data));
    // hidden fields should be added before virtual, otherwise hidden virtual fields
    // will be added twice
    data = dispatch(addHiddenFieldsToMapping(data));
    data = dispatch(addVirtualFieldsToMapping(data));
    return data;
  };
};

const shiftMap = <T>(map: Map<number, T>, newIndex: number): Map<number, T> => {
  return new Map(
    [...map.entries()].map(([colIdx, value]) => {
      if (colIdx < newIndex) {
        return [colIdx, value];
      } else {
        return [colIdx + 1, value];
      }
    })
  );
};

const shiftSet = (set: Set<number>, newIndex: number): Set<number> => {
  return new Set(
    [...set.values()].map((colIdx) => (colIdx < newIndex ? colIdx : colIdx + 1))
  );
};
