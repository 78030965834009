import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { resetState, resetStatePreservingInit } from "./coredata";

type ErrorType =
  | "developer" // a fatal error caused by the developer, such as invalid settings or a hook raising an error
  | "dromo" // a fatal error for us to deal with, like an uncaught exception in the app
  | "user" // a recoverable error caused by the end user, such as mapping a field to multiple columns
  | "data"; // a recoverable error caused by the import data, such as an invalid value

export type ErrorCode =
  | "E_BACKEND_CONNECTION_ERROR"
  | "E_SDK_CONNECTION_ERROR"
  | "E_DUPLICATE_FIELDS"
  | "E_FILE_EMPTY"
  | "E_FILE_UNPROCESSABLE"
  | "E_FILE_NO_SHEETS"
  | "E_FILE_TOO_BIG"
  | "E_HOOK_EXCEPTION"
  | "E_INVALID_APP_HOST"
  | "E_INVALID_FIELDS"
  | "E_INVALID_LICENSE_KEY"
  | "E_INVALID_SETTINGS"
  | "E_INVALID_USER"
  | "E_REQUIRES_COLUMN_MATCHING"
  | "E_REQUIRES_SHEET_SELECTION"
  | "E_SAVED_SCHEMA_NOT_FOUND"
  | "E_SUBSCRIPTION_ERROR"
  | "E_UNCAUGHT_EXCEPTION"
  | "E_VALIDATION_ERRORS"
  | "E_BEFORE_FINISH_CANCEL"
  | "E_EMPTY_RESULT";

interface IDromoErrorBase {
  type: ErrorType;
  code: ErrorCode; // a code for the class of error
  extra?: Record<string, any>; // any extra context to store
}

interface IDromoErrorNonI18n extends IDromoErrorBase {
  message: string; // a descriptive message that can be shown to the user
}

interface IDromoErrorI18n extends IDromoErrorBase {
  messageKey: string; // an i18n key to use as the error message
  messageValues?: Record<string, string>; // Replacement values in the i18n message, if applicable
}

export type IDromoError = IDromoErrorNonI18n | IDromoErrorI18n;

interface IErrorsReduxState {
  errors: IDromoError[];
}

const initialState: IErrorsReduxState = {
  errors: [],
};

const errorsSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    addError: (state, action: PayloadAction<IDromoError>) => {
      state.errors.push(action.payload);
    },

    clearError: (state, action: PayloadAction<ErrorCode>) => {
      state.errors = state.errors.filter((err) => err.code !== action.payload);
    },

    clearAllErrors: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(resetState, () => initialState);
    builder.addCase(resetStatePreservingInit, () => initialState);
  },
});

export const selectError = (
  state: IErrorsReduxState,
  code: ErrorCode
): IDromoError | null => {
  return state.errors.find((err) => err.code === code) || null;
};

export const selectFatalError = (
  state: IErrorsReduxState
): IDromoError | null => {
  return (
    state.errors.find(
      (err) => err.type === "developer" || err.type === "dromo"
    ) || null
  );
};

export const { addError, clearError, clearAllErrors } = errorsSlice.actions;
export default errorsSlice.reducer;
