import {
  EStepHook,
  IReviewStepData,
  IUploadStepData,
  IReviewStepPostHooksData,
  IBeforeFinishOutput,
  IResultMetadataWithValues,
  IReviewStepPreSubmitData,
} from "../interfaces";
import { AppThunk } from "../store/configureStore";
import { selectHeaderMapping, selectFieldMetadata } from "../helpers/Hooks";
import { selectRowsWithErrors } from "../store/reducers/coredata";
import { DEVELOPMENT_MODE_RESULT_ROW_LIMIT } from "../constants/constants";
import { addError } from "../store/reducers/errors";
import {
  consoleErrorHandler,
  executeBeforeFinishCallback,
  executeStepHooks,
  HookErrorHandlerFn,
} from "../helpers/executeHooks";
import connectionMethods from "../thunks/connection_methods";
import { bindActionCreators } from "redux";
import { FullDataWithMeta } from "./data_actions";
import {
  generateErrorsForExportWithValues,
  generateResultData,
} from "./result_data";

export type HandleUploadHookFn = (
  step: EStepHook.UPLOAD_STEP,
  data: IUploadStepData
) => Promise<void>;

export type HandleReviewHookFn = (
  step: EStepHook.REVIEW_STEP,
  data: IReviewStepData
) => Promise<void>;

export type HandleReviewPostHooksHookFn = (
  step: EStepHook.REVIEW_STEP_POST_HOOKS,
  data: IReviewStepPostHooksData
) => Promise<void>;

export type HandleReviewPreSubmitHookFn = (
  step: EStepHook.REVIEW_STEP_PRE_SUBMIT,
  data: IReviewStepPostHooksData
) => Promise<void>;

export type HandleBeforeFinishCallbackFn = (
  data: Record<string, unknown>[],
  metadata: IResultMetadataWithValues
) => Promise<IBeforeFinishOutput>;

export const runUploadStepHook = (
  handleFn: HandleUploadHookFn
): AppThunk<Promise<void>> => {
  return async (_dispatch, getState) => {
    const { originalFilename, data } = getState().coredata;

    await handleFn(EStepHook.UPLOAD_STEP, {
      filename: originalFilename,
      dataPreview: data.previewData,
    });
  };
};

export const runReviewStepHook = (
  handleFn: HandleReviewHookFn
): AppThunk<Promise<void>> => {
  return async (_dispatch, getState) => {
    const state = getState();
    const { headers } = state.coredata;

    await handleFn(EStepHook.REVIEW_STEP, {
      fields: selectFieldMetadata(state),
      rawHeaders: headers,
      headerMapping: selectHeaderMapping(state),
    });
  };
};

export const runReviewStepPostHooksHook = (
  handleFn: HandleReviewPostHooksHookFn
): AppThunk<Promise<void>> => {
  return async (_dispatch, getState) => {
    const state = getState();

    await handleFn(EStepHook.REVIEW_STEP_POST_HOOKS, {
      fields: selectFieldMetadata(state),
      headerMapping: selectHeaderMapping(state),
    });
  };
};

export const runReviewStepPreSubmitHook = (
  handleFn: HandleReviewPreSubmitHookFn
): AppThunk<Promise<void>> => {
  return async (_dispatch, getState) => {
    const state = getState();

    await handleFn(EStepHook.REVIEW_STEP_PRE_SUBMIT, {
      fields: selectFieldMetadata(state),
      headerMapping: selectHeaderMapping(state),
    });
  };
};

export const runBeforeFinishCallback = (
  fullData: FullDataWithMeta,
  handleFn: HandleBeforeFinishCallbackFn
): AppThunk<Promise<IBeforeFinishOutput>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const { settings, coredata } = state;

    const tableData = dispatch(generateResultData(fullData, false));
    const trimmedData =
      settings.importerMode === "PRODUCTION"
        ? tableData
        : tableData.slice(0, DEVELOPMENT_MODE_RESULT_ROW_LIMIT);

    const errorRows = selectRowsWithErrors(coredata);
    const errorRowsArray = Array.from(errorRows).sort((a, b) => a - b);

    const errors = dispatch(generateErrorsForExportWithValues(fullData));

    const metadata: IResultMetadataWithValues = {
      id: null,
      filename: coredata.originalFilename,
      importIdentifier: settings.importIdentifier,
      user: settings.user,
      rowsWithError: errorRowsArray,
      rawHeaders: coredata.headers,
      fields: selectFieldMetadata(state),
      errors,
    };

    return await handleFn(trimmedData, metadata);
  };
};

export const runSavedSchemaStepHooks = (
  step: EStepHook
): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const {
      savedSchemaHooks: { stepHooks },
    } = getState().settings;

    if (!stepHooks || stepHooks.length === 0) {
      return;
    }

    const errorHandler: HookErrorHandlerFn =
      process.env.JS_PLATFORM === "headless"
        ? (err: unknown, hookType: string) =>
            dispatch(addHookExceptionError(hookType, err))
        : consoleErrorHandler;

    const handleStepHooks = async (
      step: EStepHook,
      data:
        | IUploadStepData
        | IReviewStepData
        | IReviewStepPostHooksData
        | IReviewStepPreSubmitData
    ) => {
      await executeStepHooks(
        step,
        data,
        stepHooks,
        bindActionCreators(connectionMethods, dispatch),
        errorHandler
      );
    };

    switch (step) {
      case EStepHook.UPLOAD_STEP:
        await dispatch(runUploadStepHook(handleStepHooks));
        return;
      case EStepHook.REVIEW_STEP:
        await dispatch(runReviewStepHook(handleStepHooks));
        return;
      case EStepHook.REVIEW_STEP_POST_HOOKS:
        await dispatch(runReviewStepPostHooksHook(handleStepHooks));
        return;
      case EStepHook.REVIEW_STEP_PRE_SUBMIT:
        await dispatch(runReviewStepPreSubmitHook(handleStepHooks));
    }
  };
};

export const runSavedSchemaBeforeFinish = (
  fullData: FullDataWithMeta
): AppThunk<Promise<IBeforeFinishOutput>> => {
  return async (dispatch, getState) => {
    const {
      settings: {
        savedSchemaHooks: { beforeFinishCallback },
      },
    } = getState();
    if (!beforeFinishCallback) {
      return;
    }

    const errorHandler: HookErrorHandlerFn =
      process.env.JS_PLATFORM === "headless"
        ? (err: unknown, hookType: string) =>
            dispatch(addHookExceptionError(hookType, err))
        : consoleErrorHandler;
    const callback: HandleBeforeFinishCallbackFn = (data, metadata) =>
      executeBeforeFinishCallback(
        data,
        metadata,
        beforeFinishCallback,
        bindActionCreators(connectionMethods, dispatch),
        errorHandler
      );
    return await dispatch(runBeforeFinishCallback(fullData, callback));
  };
};

export const addHookExceptionError = (
  hookType: string,
  error: unknown
): AppThunk<void> => {
  return (dispatch) => {
    let errorMessage: string | undefined;
    if (error instanceof Error) errorMessage = error.message;

    let message = `An exception was raised while running ${hookType}.`;
    if (errorMessage) message += " " + errorMessage;

    dispatch(
      addError({
        type: "developer",
        code: "E_HOOK_EXCEPTION",
        message,
        extra: { error },
      })
    );
  };
};
