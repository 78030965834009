import { AbstractField, BaseTypeOpts, ITransformResult } from "../abstract";
import { DateTime, DateTimeOptions, ToISOTimeOptions } from "luxon";

export interface DateTimeOpts extends BaseTypeOpts {
  displayFormat?: string;
  outputFormat?: string;
  locale?: string;
  withSeconds?: boolean;
}

export const REF_TIME = DateTime.fromObject(
  {
    year: 1969,
    month: 7,
    day: 20,
    hour: 20,
    minute: 17,
    second: 42,
  },
  { zone: "utc" }
);

type DateTimeParseFn = (str: string, opts: DateTimeOptions) => DateTime;

export abstract class BaseDateTimeField extends AbstractField<
  DateTimeOpts,
  string
> {
  static defaultLocale = "en-US";
  static defaultDisplayFormat: string;
  static defaultOutputFormat: string;
  // this is a @typescript-eslint bug
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static isoFormatFn: (value: DateTime, opts: ToISOTimeOptions) => string;

  /* eslint-disable @typescript-eslint/unbound-method */
  static defaultParseFns: DateTimeParseFn[] = [
    DateTime.fromISO,
    // the SQL format is how we get dates from XLSX files
    DateTime.fromSQL,
    DateTime.fromRFC2822,
  ];
  /* eslint-enable @typescript-eslint/unbound-method */

  locale: string;
  displayFormat: string;
  outputFormat: string;
  parseFns: DateTimeParseFn[];
  toIsoOpts: ToISOTimeOptions;

  constructor(opts: DateTimeOpts) {
    super(opts);

    const klass = this.constructor as typeof BaseDateTimeField;

    this.toIsoOpts = {
      includeOffset: false,
      suppressMilliseconds: true,
      suppressSeconds:
        opts.withSeconds === undefined ? true : !opts.withSeconds,
    };
    this.locale = opts.locale || klass.defaultLocale;
    this.displayFormat = opts.displayFormat || klass.defaultDisplayFormat;
    this.outputFormat = opts.outputFormat || klass.defaultOutputFormat;

    this.parseFns = this.buildParseFns();
    this.invalidValueMessage =
      opts.invalidValueMessage ??
      `Invalid format. Expecting: "${this.getDisplayValue(REF_TIME.toISO())}"`;
  }

  // The canonical value stored in state is the date formatted with the display value
  transform(value: string): ITransformResult<string> {
    const dateTime = this.parse(value);

    if (dateTime) {
      const transformedValue = (
        this.constructor as typeof BaseDateTimeField
      ).isoFormatFn(dateTime, this.toIsoOpts);

      return this.transformSuccess(
        transformedValue,
        value !== transformedValue
      );
    } else {
      return this.transformFailure();
    }
  }

  getDisplayValue(value: string): string {
    const dateTime = DateTime.fromISO(value, { zone: "utc", setZone: true });
    if (!dateTime.isValid) {
      throw new Error(`Unformattable datetime: ${value}`);
    }

    return this.format(dateTime, this.displayFormat);
  }

  getOutputValue(value: string): string | number {
    const formatted = this.format(
      DateTime.fromISO(value, { zone: "utc", setZone: true }),
      this.outputFormat
    );

    // for unix timestamps, output as a number
    if (this.outputFormat.toLowerCase() === "x") return parseInt(formatted);

    return formatted;
  }

  private buildParseFns(): ((
    str: string,
    opts: DateTimeOptions
  ) => DateTime)[] {
    const displayParsers: DateTimeParseFn[] = [];

    // if the display format is ISO, we don't need to add a specific parse function,
    // we already handle parsing ISO dates
    if (this.displayFormat !== "[ISO]") {
      displayParsers.push((str: string, opts: DateTimeOptions) =>
        DateTime.fromFormat(str, this.displayFormat, opts)
      );
    }

    if (this.locale === "en-US" && this.displayFormat === "D") {
      // Luxon's en-US short date format is m/d/yyyy, like 1/19/1988.
      // However, this does not cover short years, like 1/19/88, which is
      // incidentally how Excel will output dates to CSV in the US locale.
      displayParsers.push((str: string, opts: DateTimeOptions) =>
        DateTime.fromFormat(str, "M/d/yy", opts)
      );
    }

    return [...displayParsers, ...BaseDateTimeField.defaultParseFns];
  }

  private parse(value: string): DateTime | null {
    const opts: DateTimeOptions = {
      locale: this.locale,
      // we don't really support time zones, but we need this
      // so that we assume UTC for imported times in case the output format
      // is time zone sensitive, like unix timestamps
      zone: "utc",
      // and we do this so that if the imported value does specify a time zone,
      // we use that instead of overriding it with UTC. Otherwise, if you import
      // a local time, Luxon would convert it to UTC
      setZone: true,
    };

    let dateTime: DateTime | null = null;
    for (const parseFn of this.parseFns) {
      const parseResult = parseFn(value, opts);
      if (parseResult.isValid) {
        dateTime = parseResult;
        break;
      }
    }

    return dateTime;
  }

  private format(value: DateTime, formatStr: string): string {
    if (formatStr === "[ISO]") {
      return (this.constructor as typeof BaseDateTimeField).isoFormatFn(
        value,
        this.toIsoOpts
      );
    } else {
      return value.toFormat(formatStr, { locale: this.locale });
    }
  }
}
