import type {
  IDeveloperSettings,
  IDeveloperField,
  ISelectField,
  IUser,
} from ".";

// don't remove this comment, it's used by the generate_shared_types script
// BEGIN_COPY

type MaybeAsync<T> = T | Promise<T>;

export interface IParentConnectionMethods {
  handleColumnHooks: (
    fieldName: string,
    data: IColumnHookInput[]
  ) => Promise<IColumnHookOutput[]>;

  handleRowHooks: (
    data: IRowHookInput[],
    mode: "init" | "update"
  ) => Promise<IRowHookOutputInternal[]>;

  handleStepHook: (
    step: EStepHook,
    data: IUploadStepData | IReviewStepData
  ) => void;

  handleRowDeleteHooks: (deletedRows: IRowHookInput[]) => Promise<void>;

  handleBeforeFinishCallback: (
    data: Record<string, any>[],
    metadata: IResultMetadataWithValues
  ) => Promise<IBeforeFinishOutput>;

  handleResults: (
    data: any,
    metadata: IResultMetadataWithValues
  ) => Promise<void>;

  handleCloseModal: () => void;

  handleCancel: () => void;
}

export interface IConnectionMethods {
  init: (
    licenseKey: string,
    fields: IDeveloperField[],
    settings: IDeveloperSettings,
    user: IUser,
    appHost?: string
  ) => void;
  initFromSavedSchema: (
    licenseKey: string,
    schemaName: string,
    appHost: string,
    options?: IImporterOptions
  ) => void;
  setHeaderRowOverride: (headerRowOverride: number | null) => void;
  setNumRegisteredColHooks: (numColHooks: number) => void;
  setNumRegisteredRowHooks: (numRowHooks: number) => void;
  setNumRegisteredRowDeleteHooks: (numRowDeleteHooks: number) => void;
  setEmbedInline: (embedInline: boolean) => void;
  setUser: (user: IUser) => void;
  addField: (field: IDeveloperField, position?: IPositionSpec) => void;
  removeField: (fieldKey: string) => void;
  updateInfoMessages: (messages: IMessagesForCell[]) => void;
  setDevelopmentMode: (developmentMode: boolean) => void;
  rehydrate: (rehydrateState: any, headlessImportId?: string) => void;
  addRows: (rows: IRowToAdd[]) => string[];
  removeRows: (rowIds: string[]) => void;
  setConfirmationMessage: (
    messageHTML: string,
    options?: { submitButtonText?: string; cancelButtonText?: string }
  ) => void;
}

export type IPublicConnectionMethods = Pick<
  IConnectionMethods,
  | "addField"
  | "updateInfoMessages"
  | "setHeaderRowOverride"
  | "setUser"
  | "removeField"
  | "setDevelopmentMode"
  | "addRows"
  | "removeRows"
  | "setConfirmationMessage"
>;

export type IDeveloperFieldType =
  | "string"
  | "checkbox"
  | "select"
  | "number"
  | "domain"
  | "date"
  | "ssn"
  | "datetime"
  | "phone-number"
  | "time"
  | "url"
  | "us-zip-code"
  | "country"
  | "uuid"
  | "us-state-territory"
  | "email";

export interface ICellRef {
  rowIndex: number;
  fieldKey: string;
  manyToOneIndex?: number;
}

export interface ITableMessage {
  message: string;
  level: "info" | "warning" | "error";
}

export interface IMessagesForCell extends ICellRef {
  messages: ITableMessage[];
}

export interface IColumnHook {
  fieldName: string;
  callback: (values: IColumnHookInput[]) => MaybeAsync<IColumnHookOutput[]>;
}

export interface IRowHook {
  (data: IRowHookInput, mode: "init" | "update"): MaybeAsync<IRowHookOutput>;
}

export type IBulkRowHook = (
  data: IRowHookInput[],
  mode: "init" | "update"
) => IRowHookOutputInternal[] | Promise<IRowHookOutputInternal[]>;

export interface IRowDeleteHook {
  (data: IRowHookInput): MaybeAsync<void>;
}

export interface IColumnHookInput {
  index: number;
  value: any;
  rowId: string;
}

export interface IColumnHookOutput {
  index: number;
  value?: any;
  info?: ITableMessage[];
}

export interface IRowHookCell extends IRowHookCellBasic {
  manyToOne?: IRowHookCellBasic[];
}

export interface IRowHookCellBasic {
  value: any;
  resultValue: any;
  info?: ITableMessage[];
  selectOptions?: ISelectField["selectOptions"];
}

export interface IRowHookInput {
  row: {
    [key: string]: IRowHookCell;
  };
  index: number;
  rowId: string;
}

export interface IRowHookOutput {
  row: { [key: string]: IRowCellBasic | IRowCellManyToOne };
}

export interface IRowCellBasic {
  value?: any;
  info?: ITableMessage[];
  selectOptions?: ISelectField["selectOptions"];
}

export interface IRowCellManyToOne {
  manyToOne: IRowCellBasic[];
  // these are here to prevent introducing type errors on existing
  // non-many-to-one row hooks
  value?: unknown;
  info?: unknown;
  selectOptions?: unknown;
}

export interface IRowCell extends IRowCellBasic {
  manyToOne?: IRowCellBasic[];
}

export interface IRow {
  [key: string]: IRowCell;
}

export interface IRowToAdd {
  index?: number;
  row: IRow;
}

export interface IRowHookOutputInternal extends IRowHookOutput {
  index: number;
}

export type IFieldMetadataBasic = {
  fileHeader: string | null;
  fileHeaderIndex: number | null;
  isCustom: boolean;
  manyToOne: false;
};

export type IFieldMetadataManyToOne = {
  fileHeader: null;
  fileHeaders: (string | null)[];
  fileHeaderIndex: null;
  fileHeaderIndexes: (number | null)[];
  isCustom: boolean;
  manyToOne: true;
};

export type IFieldMetadata = Record<
  string,
  IFieldMetadataBasic | IFieldMetadataManyToOne
>;

export type IError = {
  fieldKey: string;
  rowIndex: number;
  message: string;
  manyToOneIndex?: number;
};

export type IErrorWithValue = IError & { value: string };

interface ResultMetadata<ErrorType> {
  id: string | null;
  filename: string | null;
  importIdentifier: string;
  user: IUser;
  rowsWithError: number[];
  rawHeaders: string[] | null;
  fields: IFieldMetadata;
  saveForLater?: boolean;
  errors: ErrorType[];
}

export type IResultMetadata = ResultMetadata<IError>;
export type IResultMetadataWithValues = ResultMetadata<IErrorWithValue>;

export enum EStepHook {
  UPLOAD_STEP = "UPLOAD_STEP",
  REVIEW_STEP = "REVIEW_STEP",
  REVIEW_STEP_POST_HOOKS = "REVIEW_STEP_POST_HOOKS",
  REVIEW_STEP_PRE_SUBMIT = "REVIEW_STEP_PRE_SUBMIT",
}

export interface IUploadStepData {
  filename: string | null;
  dataPreview: any[][];
}

export interface IReviewStepData {
  rawHeaders: string[] | null;
  headerMapping: { [header: string]: string | undefined };
  fields: IFieldMetadata;
}

export interface IReviewStepPostHooksData {
  headerMapping: { [header: string]: string | undefined };
  fields: IFieldMetadata;
}

export interface IReviewStepPreSubmitData {
  headerMapping: { [header: string]: string | undefined };
  fields: IFieldMetadata;
}

export interface IStepHook {
  type: keyof typeof EStepHook;
  callback: (
    uploader: IPublicConnectionMethods,
    data: IUploadStepData | IReviewStepData | IReviewStepPostHooksData
  ) => MaybeAsync<void>;
}

export type IBeforeFinishCallback = (
  data: Record<string, any>[],
  metadata: IResultMetadataWithValues,
  instance: IPublicConnectionMethods
) => MaybeAsync<IBeforeFinishOutput>;

export type IBeforeFinishOutput = void | { cancel: true; message: string };

export interface IAllHooks {
  rowHooks?: IRowHook[];
  bulkRowHooks?: IBulkRowHook[];
  columnHooks?: IColumnHook[];
  stepHooks?: IStepHook[];
  rowDeleteHooks?: IRowDeleteHook[];
  beforeFinishCallback?: IBeforeFinishCallback;
}

export enum EInvalidDataBehavior {
  BLOCK_SUBMIT = "BLOCK_SUBMIT",
  REMOVE_INVALID_ROWS = "REMOVE_INVALID_ROWS",
  INCLUDE_INVALID_ROWS = "INCLUDE_INVALID_ROWS",
}

export enum EBackendSyncMode {
  DISABLED = "DISABLED",
  MAPPINGS_ONLY = "MAPPINGS_ONLY",
  FULL_DATA = "FULL_DATA",
}

export type IPositionSpec = { before: string } | { after: string };

export type IImporterOptions = {
  user?: IUser;
  developmentMode?: boolean;
  headerRowOverride?: number | null;
};
