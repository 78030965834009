import { Transition } from "@headlessui/react";
import React, {
  CSSProperties,
  KeyboardEventHandler,
  PropsWithChildren,
} from "react";
import { createPortal } from "react-dom";
import { twMerge } from "tailwind-merge";

import { ReactComponent as CloseIcon } from "../../assets/close.svg";

function Modal(
  props: PropsWithChildren<{
    show: boolean;
    onClose?: () => void;
    closeButton?: boolean;
    containerClassName?: string;
    className?: string;
    centered?: boolean;
    style?: CSSProperties;
    "data-cy"?: string;
  }>
) {
  const body = document.querySelector("body");

  const handleEscapeKey: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === "Escape") {
      props.onClose?.();
    }
  };

  return body !== null
    ? createPortal(
        <Transition
          show={props.show}
          appear
          className={twMerge(
            "bg-[#00000050] w-full min-h-screen max-h-screen fixed top-0 left-0 overflow-auto backdrop-blur-sm z-10",
            props.centered ? "grid place-items-center" : "",
            props.containerClassName
          )}
          data-cy="modal-container"
          onKeyDown={handleEscapeKey}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveTo="opacity-0"
          leaveFrom="opacity-100"
        >
          <Transition.Child
            className={twMerge(
              "global h-fit w-fit max-w-lg shadow-md relative bg-white rounded-lg",
              props.centered ? "" : "mx-auto my-10",
              props.className
            )}
            style={props.style}
            data-cy={props["data-cy"]}
            enter="transition-all duration-300"
            enterFrom="-translate-y-6"
            enterTo="translate-y-0"
            leave="transition-all duration-300"
            leaveTo="-translate-y-6"
            leaveFrom="translate-y-0"
          >
            {props.onClose !== undefined && props.closeButton !== false ? (
              <button
                onClick={props.onClose}
                className={twMerge(
                  "absolute top-8 right-4 -translate-y-1/2 p-2 hover:bg-gray-100 rounded-lg"
                )}
                data-cy="header-close-button"
              >
                <CloseIcon className="w-5 h-5" />
              </button>
            ) : null}
            {props.children}
          </Transition.Child>
        </Transition>,
        body
      )
    : null;
}

export default Modal;
