import * as React from "react";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

import { ReactComponent as CheckIcon } from "../assets/check.svg";
import { ReactComponent as ArrowBack } from "../assets/arrow-back.svg";
import { ReactComponent as CloseIcon } from "../assets/close.svg";
import {
  useStyles,
  useIsManualInput,
  useAppSelector,
  useAppDispatch,
} from "../store/hooks";
import { setExitRequested } from "../store/reducers/modals";
import Text from "./commonComponents/Text";
import { Button } from "./commonComponents/Button";
import { NotProductionBanner } from "./NotProductionBanner";
import { selectMappedSelectSpecs } from "../store/selectors";

const StepItem = ({
  isCurrent,
  isDone,
  name,
  index,
  show,
}: {
  isCurrent: boolean;
  isDone: boolean;
  name: string;
  index: number;
  show: boolean;
}) => {
  const overrideStyles = useStyles(({ stepperBar }) => ({
    color: isCurrent
      ? stepperBar.currentColor
      : isDone
      ? stepperBar.completeColor
      : stepperBar.incompleteColor,
    fontWeight: isCurrent
      ? stepperBar.currentFontWeight
      : isDone
      ? stepperBar.completeFontWeight
      : stepperBar.incompleteFontWeight,
    fontSize: stepperBar.fontSize,
  }));
  const textStyles = useStyles(({ stepperBar }) => ({
    color: stepperBar.backgroundColor,
  }));

  return show ? (
    <p
      key={name}
      style={overrideStyles}
      className={twMerge(
        "flex gap-2 items-center transition-all whitespace-nowrap text-sm md:text-base",
        isCurrent && "font-semibold text-blue",
        isDone && "font-medium text-green-700"
      )}
    >
      {isDone ? (
        <CheckIcon />
      ) : (
        <svg
          className="w-4 h-4"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="12" fill="currentColor" mask="text-mask" />
          <text
            x="50%"
            y="13"
            dominantBaseline="middle"
            textAnchor="middle"
            fill={textStyles?.color || "white"}
            fontSize="16"
            fontWeight={600}
          >
            {index + 1}
          </text>
        </svg>
      )}
      {name}
    </p>
  ) : null;
};

interface IStep {
  label: string;
  show: boolean;
}

type TModalStep =
  | "upload"
  | "setHeader"
  | "matchColumns"
  | "matchValues"
  | "review";

const STEP_INDEX = {
  upload: 0,
  setHeader: 1,
  matchColumns: 2,
  matchValues: 3,
  review: 4,
};

export interface IModalHeaderProps {
  step: TModalStep;
  onBack?: (() => void) | false;
}

const ModalHeader = (props: IModalHeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { embedInline, isManualInputOnly, uploadType, title } = useAppSelector(
    (state) => ({
      isManualInputOnly: state.settings.manualInputOnly,
      uploadType: state.coredata.data.uploadType,
      title: state.settings.title,
      embedInline: state.settings.embedInline,
    })
  );

  const overrideStyles = useStyles((styles) => ({
    backgroundColor: styles.stepperBar.backgroundColor,
    borderBottom: styles.stepperBar.borderBottom,
    globalTextColor: styles.global.primaryTextColor,
  }));
  const isManualInput = useIsManualInput();
  const schemaHasSelectFields = useAppSelector((state) =>
    [...state.fields.fieldSpecs.values()].some(
      (field) =>
        (Array.isArray(field.type) ? field.type[0] : field.type) === "select"
    )
  );
  const anySelectFieldsMapped =
    useAppSelector(selectMappedSelectSpecs).size > 0;

  const steps: IStep[] = React.useMemo(
    () => [
      {
        label: "modalHeader.upload",
        show: !isManualInputOnly,
      },
      {
        label: "modalHeader.setHeader",
        show: !isManualInput,
      },
      {
        label: "modalHeader.matchColumns",
        show: !isManualInput,
      },
      {
        label: "modalHeader.matchValues",
        show:
          STEP_INDEX[props.step] < 2
            ? schemaHasSelectFields
            : !isManualInput && anySelectFieldsMapped,
      },
      {
        label: "modalHeader.review",
        show: true,
      },
    ],
    [
      schemaHasSelectFields,
      isManualInput,
      isManualInputOnly,
      anySelectFieldsMapped,
      props.step,
    ]
  );

  const buttonStyle =
    overrideStyles?.globalTextColor !== undefined &&
    overrideStyles.globalTextColor !== ""
      ? { color: overrideStyles.globalTextColor }
      : {};

  return (
    <>
      <NotProductionBanner />
      <div
        className="p-7 relative flex flex-col items-center bg-[white] border-b border-gray-200 rounded-t-lg"
        style={overrideStyles}
      >
        {typeof props.onBack === "function" && (
          <Button
            onClick={props.onBack}
            theme="secondary"
            className="absolute top-4 md:top-1/2 left-4 md:-translate-y-1/2 gap-2"
            data-cy="header-back-button"
          >
            <ArrowBack />
            {t("common.goBack")}
          </Button>
        )}
        {uploadType !== "INITIAL_DATA_MAPPED" ? (
          <div className="mx-auto !mt-10 md:!mt-0 flex gap-4 sm:gap-8 max-w-full overflow-auto scrollbar-hide">
            {steps.map((step, idx: number) => (
              <StepItem
                key={step.label}
                show={step.show}
                isCurrent={step.label === `modalHeader.${props.step}`}
                isDone={
                  idx <
                  steps.findIndex(
                    (s) => s.label === `modalHeader.${props.step}`
                  )
                }
                name={t(step.label)}
                index={steps.filter((s) => s.show).indexOf(step)}
              />
            ))}
          </div>
        ) : (
          title && (
            <div className="flex justify-center">
              <Text type="h1">{title}</Text>
            </div>
          )
        )}
        {!embedInline && (
          <button
            onClick={() => dispatch(setExitRequested(true))}
            className={twMerge(
              "absolute top-8 right-4 -translate-y-1/2 p-2 hover:bg-gray-100 rounded-lg",
              uploadType === "INITIAL_DATA_MAPPED" && "top-7"
            )}
            data-cy="header-close-button"
            style={buttonStyle}
          >
            <CloseIcon className="w-5 h-5" />
          </button>
        )}
      </div>
    </>
  );
};

export default ModalHeader;
