import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../store/hooks";
import { Button } from "../commonComponents/Button";
import Text from "../commonComponents/Text";
import StepContainer from "../commonComponents/StepContainer";
import { EnhanceDataCard } from "./EnhanceDataCard";
import { Card } from "../commonComponents/Card";
import { getLabelsFromFields } from "../../helpers/CoreDataHelpers";
import { selectMappedSelectSpecs } from "../../store/selectors";
import { useStepNavigation } from "../../thunks/step_navigation";
import Toggle from "../commonComponents/Toggle";
import HelpText from "../HelpText";

export function truncate(str: string, length: number) {
  return str.length > length ? str.substring(0, length) + "..." : str;
}

export function EnhanceData() {
  const { canGoBack, goBack, goToNextStep } = useStepNavigation();
  const { valCountsInColumn, mappedSelectFields, stepSettings } =
    useAppSelector((state) => ({
      valCountsInColumn: state.coredata.data.valCountsInColumn,
      headerRow: state.coredata.rawDataHeaderRow,
      mappedSelectFields: selectMappedSelectSpecs(state),
      stepSettings: state.settings.matchValuesStep,
    }));

  const [filterMatched, setFilterMatchedColumns] = useState(false);

  const handleToggleFilterMatched = () => {
    setFilterMatchedColumns(!filterMatched);
  };

  const selectFields = [...mappedSelectFields].map(([index, field]) => ({
    index,
    field,
  }));

  const { t } = useTranslation();

  const getValues = (columnIndex: number): string[] => {
    return Array.from(valCountsInColumn?.get(columnIndex)?.keys() || []).filter(
      (val) => val.trim() !== ""
    );
  };

  const labels = getLabelsFromFields(selectFields.map((sf) => sf.field));

  return (
    <>
      <StepContainer
        data-cy="CleanDataModal"
        onBack={canGoBack && goBack}
        step="matchValues"
      >
        <div className="flex gap-2 items-end">
          <div className="flex-1">
            <Text type="h1" className="mb-2">
              {t("modalHeader.matchValues")}
            </Text>
            <Text type="body">{t("dataCleaningModal.subtitle")}</Text>
          </div>
          <div className="flex-0">
            <div className="flex gap-2 items-center self-end">
              <Toggle
                onChange={handleToggleFilterMatched}
                checked={filterMatched}
                disabled={false}
                value="showUnmatchedToggle"
                label={t("columnMatchModal.showUnmatchedToggle")}
                data-cy="match-values-show-unmatched-toggle"
              />
            </div>
          </div>
        </div>

        {stepSettings.helpText && <HelpText content={stepSettings.helpText} />}

        <Card className="my-4 mt-6">
          <div className="bg-ice-50 !border !border-ice-300 divide-y divide-ice-300">
            {selectFields.map((column, idx) => {
              const exactMatchOnly: boolean =
                Array.isArray(column.field.type) &&
                !!column.field.type[1].exactMatchOnly;

              return (
                <EnhanceDataCard
                  key={column.field?.key}
                  name={labels[idx]}
                  options={column.field.selectOptions}
                  values={getValues(column.index)}
                  columnIndex={column.index}
                  filterMatched={filterMatched}
                  exactMatchOnly={exactMatchOnly}
                />
              );
            })}
          </div>
        </Card>
        <div className="flex justify-end items-center gap-2 w-full mt-6">
          <Button
            onClick={goToNextStep}
            data-cy="auto-clean-continue-button"
            autoFocus
          >
            {t("dataCleaningModal.continue")}
          </Button>
        </div>
      </StepContainer>
    </>
  );
}
