import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "./reducers";
import { fieldFromDeveloperField, IField } from "../fields";
import { IDeveloperField, ISelectField, IError } from "../interfaces";
import { omit } from "lodash";

export const selectMappedFieldInstances = createSelector(
  (state: RootState) => state.fields.fieldSpecs,
  (state: RootState) => state.fields.columnMapping,
  (state: RootState) => state.coredata.selectOptionOverrides,
  (fieldSpecs, columnMapping, selectOptionOverrides) => {
    const fieldInstances = new Map<number, IField>();

    columnMapping.forEach(({ key }, colIndex) => {
      const field = fieldFromDeveloperField(
        fieldSpecs.get(key)!,
        selectOptionOverrides.get(colIndex)
      );
      fieldInstances.set(colIndex, field);
    });

    return fieldInstances;
  }
) as (state: RootState) => Map<number, IField>;

export const selectMappedSpecs = createSelector(
  (state: RootState) => state.fields.fieldSpecs,
  (state: RootState) => state.fields.columnMapping,
  (fieldSpecs, columnMapping) => {
    const specMap = new Map<number, IDeveloperField>();

    for (const [colIndex, { key }] of columnMapping.entries()) {
      specMap.set(colIndex, fieldSpecs.get(key)!);
    }

    return specMap;
  }
) as (state: RootState) => Map<number, IDeveloperField>;

export const selectVisibleMappedSpecs = (
  state: RootState
): Map<number, IDeveloperField> => {
  const mappedSpecs = selectMappedSpecs(state);

  return new Map([...mappedSpecs].filter(([_idx, spec]) => !spec.hidden));
};

export const selectMappedSelectSpecs = createSelector(
  selectMappedSpecs,
  (mappedSpecs) => {
    return new Map<number, ISelectField>(
      [...mappedSpecs].filter(([_colIndex, field]) => {
        return (
          (Array.isArray(field.type) ? field.type[0] : field.type) === "select"
        );
      }) as [number, ISelectField][]
    );
  }
);

export const selectOrderedSpecs = createSelector(
  (state: RootState) => state.fields.columnMapping,
  (state: RootState) => state.fields.fieldSpecs,
  (columnMapping, fieldSpecs) => {
    const orderedKeys = [...columnMapping]
      .sort(([idxA], [idxB]) => idxA - idxB)
      .map(([_, { key }]) => key);

    return orderedKeys.map((key) => fieldSpecs.get(key)!);
  }
);

export type ManyToOneIndexEntry = { manyToOne: true; indexes: number[] };
export type OneToOneIndexEntry = { manyToOne: false; index: number };
export type IndexEntry = OneToOneIndexEntry | ManyToOneIndexEntry;

export const selectKeyToIndexMap = createSelector(
  selectMappedSpecs,
  (mappedSpecs) => {
    const mapping = new Map<string, IndexEntry>();

    for (const [colIndex, field] of mappedSpecs) {
      if (field.manyToOne) {
        const entry: IndexEntry = (mapping.get(
          field.key
        ) as ManyToOneIndexEntry) ?? {
          manyToOne: true,
          indexes: [],
        };

        entry.indexes.push(colIndex);
        mapping.set(field.key, entry);
      } else {
        mapping.set(field.key, {
          manyToOne: false,
          index: colIndex,
        });
      }
    }

    return mapping;
  }
);

type IErrorWithCol = IError & { colIndex: number };
export const selectErrorsForExportWithColIndex = (
  state: RootState
): IErrorWithCol[] => {
  const errors: IErrorWithCol[] = [];
  const colIdxToFieldKeyMap: Map<number, string> = new Map();
  const keyToIndexMap = selectKeyToIndexMap(state);
  const fieldSpecs = selectMappedSpecs(state);

  [...fieldSpecs.entries()]
    .sort(([colIdx1], [colIdx2]) => colIdx1 - colIdx2)
    .forEach(([fullDataColIdx, field]) => {
      colIdxToFieldKeyMap.set(fullDataColIdx, field.key);
    });

  state.coredata.tableMessages.forEach((rowMessages, rowIndex) => {
    rowMessages.forEach((messages, colIndex) => {
      const errorMessages = messages
        .filter((m) => m.level === "error")
        .map((m) => m.message);

      const indexEntry = keyToIndexMap.get(colIdxToFieldKeyMap.get(colIndex)!);

      if (!indexEntry) return;

      errorMessages.forEach((message) => {
        const errorObj: IErrorWithCol = {
          fieldKey: colIdxToFieldKeyMap.get(colIndex)!,
          rowIndex,
          colIndex,
          message,
        };

        if (indexEntry.manyToOne) {
          errorObj.manyToOneIndex = indexEntry.indexes.indexOf(colIndex);
        }
        errors.push(errorObj);
      });
    });
  });

  return errors;
};

export const selectErrorsForExport = (state: RootState): IError[] => {
  return selectErrorsForExportWithColIndex(state).map((error) =>
    omit(error, "colIndex")
  );
};

export const selectHasMatchableSelectFields = (
  state: RootState
): boolean | null => {
  const mappedSelectFields = selectMappedSelectSpecs(state);
  const valCountsInColumn = state.coredata.data.valCountsInColumn;

  if (mappedSelectFields.size === 0) return false;

  // We can't make a determination if we haven't calculated valCounts yet
  if (valCountsInColumn === null) return null;

  // check to see if we have unique value sets for any mapped select fields
  return [...mappedSelectFields.keys()].some((colIdx) =>
    valCountsInColumn.has(colIdx)
  );
};
