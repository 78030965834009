import React, { useMemo } from "react";
import type { Connection } from "penpal";
import { ParentConnectionContext } from "../components/ParentConnectionContext";
import {
  EStepHook,
  IColumnHook,
  IColumnHookInput,
  IColumnHookOutput,
  IRowDeleteHook,
  IResultMetadata,
  IReviewStepData,
  IRowHook,
  IRowHookInput,
  IRowHookOutputInternal,
  IStepHook,
  IUploadStepData,
  IBulkRowHook,
  IBeforeFinishCallback,
  IBeforeFinishOutput,
  IPublicConnectionMethods,
  IParentConnectionMethods,
  IResultMetadataWithValues,
} from "../interfaces";
import {
  consoleErrorHandler,
  executeBeforeFinishCallback,
  executeColumnHooks,
  executeRowDeleteHooks,
  executeRowHooks,
  executeStepHooks,
} from "./executeHooks";

export interface IUploaderInternal extends IPublicConnectionMethods {
  columnHooks: IColumnHook[];
  rowHooks: { callback: IRowHook }[];
  bulkRowHooks: IBulkRowHook[];
  stepHooks: IStepHook[];
  rowDeleteHooks: IRowDeleteHook[];
  beforeFinishCallback?: IBeforeFinishCallback;
  resultsCallback?: (
    data: any,
    metadata: IResultMetadata
  ) => Promise<void> | void;
  cancelCallback?: () => void;
  close: () => void;
}

type ProviderProps = React.PropsWithChildren<{ parent: IUploaderInternal }>;

export const MockParentConnectionProvider: React.FC<ProviderProps> = ({
  parent,
  children,
}) => {
  const connection = useMemo(() => createMockConnection(parent), [parent]);

  return (
    <ParentConnectionContext.Provider value={connection}>
      {children}
    </ParentConnectionContext.Provider>
  );
};

export function createMockConnection(
  self: IUploaderInternal
): Connection<IParentConnectionMethods> {
  return {
    destroy: () => {
      /* do nothing */
    },
    promise: Promise.resolve({
      /**
       * Called for each column/field. Sends all data and expects all the
       * relevant column hooks to be evaluated.
       *
       * @param fieldName the fieldname key for the column
       * @param data the full column of data for the particular key
       */
      async handleColumnHooks(
        fieldName: string,
        data: IColumnHookInput[]
      ): Promise<IColumnHookOutput[]> {
        // Get all the hooks for this particular field
        const hooks = self.columnHooks.filter((h) => h.fieldName === fieldName);
        return await executeColumnHooks(hooks, data, consoleErrorHandler);
      },

      /**
       * Called singularly for each row. Goes through all row hooks and
       * evaluates them.
       *
       * @param data an array of row data objects
       */
      async handleRowHooks(
        data: IRowHookInput[],
        mode: "init" | "update"
      ): Promise<IRowHookOutputInternal[]> {
        return await executeRowHooks(
          data,
          mode,
          self.rowHooks.map((rh) => rh.callback),
          self.bulkRowHooks,
          consoleErrorHandler
        );
      },
      /**
       * Called singularly for each row. Goes through all delete hooks and
       * evaluates them.
       *
       * @param data an array of row data objects
       */
      async handleRowDeleteHooks(deletedRows: IRowHookInput[]): Promise<void> {
        await executeRowDeleteHooks(
          deletedRows,
          self.rowDeleteHooks,
          consoleErrorHandler
        );
      },

      async handleStepHook(
        step: EStepHook,
        data: IUploadStepData | IReviewStepData
      ) {
        return await executeStepHooks(
          step,
          data,
          self.stepHooks,
          self,
          consoleErrorHandler
        );
      },

      async handleBeforeFinishCallback(
        data: Record<string, any>[],
        metadata: IResultMetadataWithValues
      ): Promise<IBeforeFinishOutput> {
        if (self.beforeFinishCallback) {
          return await executeBeforeFinishCallback(
            data,
            metadata,
            self.beforeFinishCallback,
            self,
            consoleErrorHandler
          );
        }
      },
      /**
       * Called after the user has completed the Dromo flow, send the
       * cleaned data to the developer
       *
       * @param data cleaned data after the flow is complete
       */
      async handleResults(
        data: Record<string, any>[],
        metadata: IResultMetadata
      ): Promise<void> {
        if (self.resultsCallback) {
          try {
            return await self.resultsCallback(data, metadata);
          } catch (err) {
            console.error(
              "[Dromo-External-Error] There was an error in your onResult callback.",
              err
            );
          }
        }
        self.close();
      },
      /**
       * Called when the modal has been closed by the finishing of the flow
       */
      async handleCloseModal() {
        self.close();
      },
      /**
       * Called when the modal has been closed due to the user canceling the
       * flow
       */
      async handleCancel() {
        if (self.cancelCallback) {
          try {
            self.cancelCallback();
          } catch (err) {
            console.error(
              "[Dromo-External-Error] There was an error in your cancel callback.",
              err
            );
          }
        }
        self.close();
      },
    }),
  };
}
