import { AppThunk } from "../store/configureStore";
import {
  IDeveloperField,
  ISelectField,
  IDeveloperSettings,
  IDeveloperSettingsStrict,
  IUser,
} from "../interfaces";
import {
  DeveloperSettingsSchema,
  DEFAULT_LOCALE,
} from "../helpers/schemas/settings";
import { DeveloperFieldsSchema } from "../helpers/schemas/fields";
import { UserSchema } from "../helpers/schemas/user";
import { StyleOverridesSchema } from "../helpers/schemas/style_overrides";
import { setFromDeveloperSettings, setUser } from "../store/reducers/settings";
import { addError } from "../store/reducers/errors";
import { fromZodError } from "zod-validation-error";
import { initializeFromDeveloperFields } from "../store/reducers/fields";

export const initializeFields = (
  developerFields: IDeveloperField[]
): AppThunk<IDeveloperField[] | null> => {
  return (dispatch) => {
    const parseResult = DeveloperFieldsSchema.safeParse(developerFields);
    if (!parseResult.success) {
      dispatch(
        addError({
          type: "developer",
          code: "E_INVALID_FIELDS",
          message: fromZodError(parseResult.error).message,
        })
      );
      return null;
    }

    validateSelectFields(parseResult.data);

    dispatch(initializeFromDeveloperFields(parseResult.data));
    return parseResult.data;
  };
};

export const stripSettings = (
  settings: IDeveloperSettingsStrict
): AppThunk<IDeveloperSettingsStrict> => {
  return (_dispatch, getState) => {
    const { backendCapabilities } = getState().settings;

    if (
      backendCapabilities.allow_auto_map_headers === false &&
      settings.autoMapHeaders
    ) {
      console.error(
        "[Dromo-External-Error] The Dromo account is not authorized to use auto map headers"
      );
      settings.autoMapHeaders = false;
    }

    if (
      backendCapabilities.allow_custom_fields === false &&
      settings.allowCustomFields
    ) {
      console.error(
        "[Dromo-External-Error] The Dromo account is not authorized to use custom fields"
      );
      settings.allowCustomFields = false;
    }

    if (
      backendCapabilities.allow_user_transforms === false &&
      settings.reviewStep?.enableUserTransformations
    ) {
      console.error(
        "[Dromo-External-Error] The Dromo account is not authorized to use user transformations"
      );
      settings.reviewStep.enableUserTransformations = false;
    }

    if (backendCapabilities.allow_style_overrides === false) {
      settings.styleOverrides = StyleOverridesSchema.parse({});
    }

    if (
      backendCapabilities.allow_help_text === false &&
      (settings.reviewStep?.helpText ||
        settings.matchingStep?.helpText ||
        settings.matchValuesStep?.helpText ||
        settings.uploadStep?.helpText)
    ) {
      console.error(
        "[Dromo-External-Error] The Dromo account is not authorized to use help text"
      );
      if (settings.reviewStep?.helpText) {
        settings.reviewStep.helpText = null;
      }
      if (settings.matchingStep?.helpText) {
        settings.matchingStep.helpText = null;
      }
      if (settings.matchValuesStep?.helpText) {
        settings.matchValuesStep.helpText = null;
      }
      if (settings.uploadStep?.helpText) {
        settings.uploadStep.helpText = null;
      }
    }

    if (
      backendCapabilities.allow_initial_data === false &&
      settings.initialData
    ) {
      console.error(
        "[Dromo-External-Error] The Dromo account is not authorized to use initial data"
      );
      settings.initialData = null;
    }

    if (backendCapabilities.allow_i18n === false && settings.locale) {
      console.error(
        "[Dromo-External-Error] The Dromo account is not authorized to use i18n"
      );
      settings.locale = DEFAULT_LOCALE;
    }

    if (
      backendCapabilities.row_limit !== undefined &&
      backendCapabilities.row_limit !== null &&
      backendCapabilities.row_limit !== 0 &&
      (settings.maxRecords === undefined ||
        settings.maxRecords === null ||
        settings.maxRecords > backendCapabilities.row_limit)
    ) {
      settings.maxRecords = backendCapabilities.row_limit;
      console.error(
        `[Dromo-External-Error] The Dromo account has a row limit of ${backendCapabilities.row_limit}.`
      );
    }

    return settings;
  };
};

export const validateSettings = (
  settings: IDeveloperSettings
): AppThunk<IDeveloperSettingsStrict | null> => {
  return (dispatch) => {
    const parseResult = DeveloperSettingsSchema.safeParse(settings);
    if (!parseResult.success) {
      dispatch(
        addError({
          type: "developer",
          code: "E_INVALID_SETTINGS",
          message: fromZodError(parseResult.error, {
            prefix: "Invalid Dromo settings",
          }).message,
        })
      );

      return null;
    }

    return parseResult.data;
  };
};

export const initializeSettings = (
  settings: IDeveloperSettingsStrict
): AppThunk<IDeveloperSettingsStrict> => {
  return (dispatch) => {
    const strippedSettings = dispatch(stripSettings(settings));
    dispatch(setFromDeveloperSettings(strippedSettings));
    return strippedSettings;
  };
};

export const validateAndSetUser = (user: IUser): AppThunk<IUser | null> => {
  return (dispatch) => {
    const parseResult = UserSchema.safeParse(user);
    if (!parseResult.success) {
      dispatch(
        addError({
          type: "developer",
          code: "E_INVALID_USER",
          message: fromZodError(parseResult.error, {
            prefix: "Invalid Dromo user",
          }).message,
        })
      );

      return null;
    }

    dispatch(setUser(parseResult.data));
    return parseResult.data;
  };
};

const validateSelectFields = (fields: IDeveloperField[]): void => {
  const selectFields = fields.filter(
    ({ type }) => type === "select"
  ) as ISelectField[];

  for (const selectField of selectFields) {
    const seenLabels = new Set<string>();
    const dupLabels = [];

    for (const { label } of selectField.selectOptions) {
      if (seenLabels.has(label)) dupLabels.push(label);
      seenLabels.add(label);
    }

    if (dupLabels.length > 0) {
      console.error(
        `[Dromo-External-Error] Warning: Select field '${
          selectField.key
        }' has select options with duplicate labels: ${dupLabels.join(", ")}.` +
          "\nHaving multiple select options with the same label results in undefined behavior."
      );
    }
  }
};

export const trimDataForMaxRecords = <T extends any[]>(
  data: T
): AppThunk<T> => {
  return (_dispatch, getState) => {
    const { maxRecords } = getState().settings;

    if (maxRecords === null || maxRecords >= data.length) return data;

    data.length = maxRecords;
    return data;
  };
};
