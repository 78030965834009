import { AppThunk } from "../store/configureStore";
import APIClient from "../helpers/APIClient";
import { PresignedPost } from "../interfaces/api";
import FormData from "form-data";
import { IncomingMessage } from "http";

interface UploadParams {
  uploadType: "clean" | "error";
  numDataRows?: number;
  fieldOrder?: string[] | null;
}

// This is a simplified version of uploadCleanedFileHelper in APIHelpers.
// It differs by using a node-compatible function for uploading form data.

export const uploadCleanedFile = (
  file: File,
  isSavedProgress: boolean,
  numDataRows: number,
  fieldOrder: string[] | null
): AppThunk<Promise<{ uploadId: string }>> => {
  return uploadFile(file, isSavedProgress, {
    uploadType: "clean",
    numDataRows,
    fieldOrder,
  });
};

export const uploadErrorFile = (
  file: File
): AppThunk<Promise<{ uploadId: string }>> => {
  return uploadFile(file, false, { uploadType: "error" });
};

const uploadFile = (
  file: File,
  isSavedProgress: boolean,
  uploadParams: UploadParams
): AppThunk<Promise<{ uploadId: string }>> => {
  return async (_dispatch, getState) => {
    const state = getState();
    const { settings, coredata } = state;
    const api = new APIClient(state.settings.licenseKey);
    const { signature, uploadId } = await api.getSignedUploadUrl({
      fileName: file.name,
      user: settings.user,
      rawDataUploadId: coredata.uploadId,
      importIdentifier: settings.importIdentifier,
      ...uploadParams,
    });

    const { fields, url } = signature as PresignedPost;

    // custom extension added to the Blob polyfill to get a native node buffer
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const fileBuffer = await file.nodeBuffer();

    await postFormData(url, { ...fields, file: fileBuffer });

    await api.updateUpload(
      uploadParams.uploadType,
      uploadId,
      isSavedProgress ? "SAVED_PROGRESS" : "PROCESSED"
    );

    return { uploadId };
  };
};

const postFormData = (
  url: string,
  formDataObj: Record<string, unknown>
): Promise<IncomingMessage> => {
  const form = new FormData();

  for (const [key, value] of Object.entries(formDataObj)) {
    form.append(key, value);
  }

  return new Promise((resolve, reject) => {
    form.submit(url, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  });
};
