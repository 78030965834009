import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
import {
  IDeveloperField,
  IPositionSpec,
  IDeveloperSelectOption,
} from "../../interfaces";
import { AppThunk } from "../configureStore";
import { cloneDeep, omit } from "lodash";

// A map of field keys to corresponding field spec
type FieldSpecMap = Map<string, IDeveloperField>;

export const MatchTypes = {
  Exact: "EXACT", // the header exactly matched the field
  Fuzzy: "FUZZY", // the header was fuzzy matched to the field
  AI: "AI", // the header was matched using AI matching
  User: "USER", // the user matched the column to a field
  Custom: "CUSTOM", // this field was added as a custom field
  AutoMap: "AUTOMAP", // the field was matched via the autoMapHeaders feature
  Virtual: "VIRTUAL", // this field was added using addField in a hook
} as const;

export type MatchType = typeof MatchTypes[keyof typeof MatchTypes];

interface MappingTarget {
  key: string;
  matchType: MatchType;
}

// Map of column index in fullData to field key
export type ColumnMapping = Map<number, MappingTarget>;

export interface ISelectFieldOption extends IDeveloperSelectOption {
  matchType?: MatchType;
}

export type SelectFieldMapping = Map<number, Map<string, ISelectFieldOption>>;

type VirtualField = { field: IDeveloperField; position: IPositionSpec | null };

export interface IFieldsReducerState {
  fieldOrder: string[]; // only used before column mapping
  virtualFields: VirtualField[];
  addedEmptyFields: Set<string>;
  fieldSpecs: FieldSpecMap;
  columnMapping: ColumnMapping;
  selectFieldMapping: SelectFieldMapping;
  ignoredColumns: Set<number>;
  removedFields: Map<string, IDeveloperField>;
}

export const initialState: IFieldsReducerState = {
  fieldOrder: [],
  virtualFields: [],
  addedEmptyFields: new Set(),
  fieldSpecs: new Map(),
  columnMapping: new Map(),
  selectFieldMapping: new Map(),
  ignoredColumns: new Set(),
  removedFields: new Map(),
};

const fieldsSlice = createSlice({
  name: "fields",
  initialState,
  reducers: {
    initializeFromDeveloperFields: (
      state,
      action: PayloadAction<IDeveloperField[]>
    ) => {
      state.fieldOrder = action.payload.map(({ key }) => key);
      state.fieldSpecs = new Map(
        action.payload.map((field) => [field.key, field])
      );
    },

    setColumnMapping: (state, action: PayloadAction<ColumnMapping>) => {
      state.columnMapping = action.payload;
    },

    mapColumn: {
      prepare: (colIndex: number, key: string, matchType: MatchType) => ({
        payload: { key, colIndex, matchType },
      }),
      reducer: (
        state,
        action: PayloadAction<{
          key: string;
          colIndex: number;
          matchType: MatchType;
        }>
      ) => {
        let { key, colIndex, matchType } = action.payload;

        // unmap first, so if we were previously mapped to a custom field,
        // we'll clean it up before remapping
        fieldsSlice.caseReducers.unmapColumn(state, {
          payload: colIndex,
          type: "fields/unmapColumn",
        });
        state.ignoredColumns.delete(colIndex);

        if (matchType === MatchTypes.Custom) {
          const label = key;

          // We don't want the custom field key to conflict with any existing field specs
          // (schema fields, mapped or unmapped, or any other custom fields)
          if (state.fieldSpecs.has(key)) {
            let customKeyIndex = 1;
            while (state.fieldSpecs.has(`${key}_${customKeyIndex}`)) {
              customKeyIndex++;
            }
            key = `${key}_${customKeyIndex}`;
          }

          state.fieldSpecs.set(key, { key, label });
        }

        state.columnMapping.set(colIndex, {
          key,
          matchType,
        });
      },
    },

    unmapColumn: (state, action: PayloadAction<number>) => {
      const mapping = state.columnMapping.get(action.payload);
      if (mapping === undefined) return;

      state.columnMapping.delete(action.payload);
      if (mapping.matchType === MatchTypes.Custom) {
        state.fieldSpecs.delete(mapping.key);
      }
    },

    setIgnoredColumns: (state, action: PayloadAction<Set<number>>) => {
      state.ignoredColumns = action.payload;
    },

    mergeIgnoredColumns: (state, action: PayloadAction<Set<number>>) => {
      action.payload.forEach((val) => state.ignoredColumns.add(val));
    },

    setColumnIgnored: {
      prepare: (colIndex: number, ignored: boolean) => ({
        payload: { colIndex, ignored },
      }),
      reducer: (
        state,
        action: PayloadAction<{ colIndex: number; ignored: boolean }>
      ) => {
        const { colIndex, ignored } = action.payload;

        if (ignored) {
          state.columnMapping.delete(colIndex);
          state.ignoredColumns.add(colIndex);
        } else {
          state.ignoredColumns.delete(colIndex);
        }
      },
    },

    addEmptyField: (state, action: PayloadAction<string>) => {
      state.addedEmptyFields.add(action.payload);
    },

    removeEmptyField: (state, action: PayloadAction<string>) => {
      state.addedEmptyFields.delete(action.payload);
    },

    registerVirtualField: {
      prepare: (
        virtualField: IDeveloperField,
        position?: IPositionSpec | null
      ) => {
        return { payload: { field: virtualField, position: position ?? null } };
      },
      reducer: (
        state,
        action: PayloadAction<{
          field: IDeveloperField;
          position: IPositionSpec | null;
        }>
      ) => {
        state.virtualFields.push(action.payload);
      },
    },

    removeField: (state, action: PayloadAction<string>) => {
      const removedKey = action.payload;

      if (!state.removedFields.has(removedKey)) {
        state.removedFields.set(removedKey, state.fieldSpecs.get(removedKey)!);
      }

      state.fieldOrder = state.fieldOrder.filter(
        (key) => key !== action.payload
      );

      state.fieldSpecs.delete(action.payload);

      const colIndexes = [...state.columnMapping]
        .filter(([_index, mapping]) => mapping.key === action.payload)
        .map(([index]) => index);

      for (const colIndex of colIndexes) {
        state.columnMapping.delete(colIndex);
      }
    },

    // clears the select field mapping for the given column index
    clearSelectFieldMapping: (state, action: PayloadAction<number>) => {
      state.selectFieldMapping.delete(action.payload);
    },

    updateSelectFieldMapping: {
      prepare: (
        columnIndex: number,
        uniqueColumnValue: string,
        selectFieldValue: ISelectFieldOption | null
      ) => {
        return {
          payload: { columnIndex, uniqueColumnValue, selectFieldValue },
        };
      },
      reducer: (
        state,
        action: PayloadAction<{
          columnIndex: number;
          uniqueColumnValue: string;
          selectFieldValue: ISelectFieldOption | null;
        }>
      ) => {
        const { columnIndex, uniqueColumnValue, selectFieldValue } =
          action.payload;

        if (!state.selectFieldMapping.has(columnIndex)) {
          state.selectFieldMapping.set(columnIndex, new Map());
        }

        if (selectFieldValue === null) {
          state.selectFieldMapping.get(columnIndex)!.delete(uniqueColumnValue);
        } else {
          state.selectFieldMapping
            .get(columnIndex)!
            .set(uniqueColumnValue, selectFieldValue);
        }
      },
    },

    setSelectFieldMapping: (
      state,
      action: PayloadAction<SelectFieldMapping>
    ) => {
      state.selectFieldMapping = action.payload;
    },

    setFieldState: (_state, action: PayloadAction<IFieldsReducerState>) => {
      return action.payload;
    },

    setPartialFieldState: (
      state,
      action: PayloadAction<Partial<IFieldsReducerState>>
    ) => {
      for (const [key, value] of Object.entries(action.payload)) {
        // @ts-expect-error TS not able to infer that value type matches key
        state[key] = value;
      }
    },
  },

  extraReducers: (builder) => {
    // can't use the action object here because it causes a circular dependency
    builder.addCase("coredata/resetState", () => initialState);
  },
});

export const selectFieldSpecAtColIndex = (
  state: IFieldsReducerState,
  colIndex: number
): IDeveloperField | undefined => {
  const mapping = state.columnMapping.get(colIndex);
  if (!mapping) return;

  const fieldSpec = state.fieldSpecs.get(mapping.key);
  return fieldSpec;
};

export const selectMatchableFieldSpecs = (
  state: IFieldsReducerState
): IDeveloperField[] => {
  return state.fieldOrder
    .map((fieldKey) => state.fieldSpecs.get(fieldKey))
    .filter((field) => field && !field.hidden) as IDeveloperField[];
};

type InvertedMapTarget = { colIndex: number; matchType: MatchType };
export const selectInvertedColumnMapping = (
  state: IFieldsReducerState
): Map<string, InvertedMapTarget> => {
  const inverted = new Map<string, InvertedMapTarget>();

  for (const [colIndex, { key, matchType }] of state.columnMapping) {
    inverted.set(key, { colIndex, matchType });
  }

  return inverted;
};

export const {
  setColumnMapping,
  mapColumn,
  unmapColumn,
  setIgnoredColumns,
  setColumnIgnored,
  registerVirtualField,
  removeField,
  mergeIgnoredColumns,
  addEmptyField,
  removeEmptyField,
  clearSelectFieldMapping,
  updateSelectFieldMapping,
  setSelectFieldMapping,
  setFieldState,
  setPartialFieldState,
} = fieldsSlice.actions;

export type SnapshotName = "INIT" | "PREREVIEW";

type StateWithSnapshots<T> = T & { snapshots: Map<SnapshotName, T> };

export type FieldsStateWithSnapshots = StateWithSnapshots<IFieldsReducerState>;

const withSnapshots = (
  reducer: typeof fieldsSlice.reducer
): ((
  state: FieldsStateWithSnapshots | undefined,
  action: AnyAction
) => FieldsStateWithSnapshots) => {
  const initialState: FieldsStateWithSnapshots = {
    ...reducer(undefined, { type: "" }),
    snapshots: new Map(),
  };

  return (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case "SNAPSHOT":
        return {
          ...state,
          snapshots: new Map(state.snapshots).set(
            action.payload,
            cloneDeep(omit(state, ["snapshots"]))
          ),
        };
      case "RESTORE":
        if (!state.snapshots.has(action.payload)) {
          return state;
        }

        return {
          ...cloneDeep(state.snapshots.get(action.payload)!),
          snapshots: state.snapshots,
        };
      case "SET_SNAPSHOT": {
        const { snapshotName, snapshot } = action.payload;

        return {
          ...state,
          snapshots: new Map(state.snapshots).set(snapshotName, snapshot),
        };
      }
      default: {
        const result = reducer(state, action);
        return { ...result, snapshots: state.snapshots };
      }
    }
  };
};

export const createSnapshot = (snapshotName: SnapshotName): AnyAction => {
  return { type: "SNAPSHOT", payload: snapshotName };
};

export const restoreSnapshot = (snapshotName: SnapshotName): AnyAction => {
  return { type: "RESTORE", payload: snapshotName };
};

export const setSnapshot = (
  snapshotName: SnapshotName,
  snapshot: IFieldsReducerState
): AnyAction => {
  return { type: "SET_SNAPSHOT", payload: { snapshotName, snapshot } };
};

export default withSnapshots(fieldsSlice.reducer);

export const initializeFromDeveloperFields = (
  developerFields: IDeveloperField[]
): AppThunk => {
  return (dispatch) => {
    const result = dispatch(
      fieldsSlice.actions.initializeFromDeveloperFields(developerFields)
    );
    dispatch(createSnapshot("INIT"));
    return result;
  };
};

export const getOriginalFields = (
  state: FieldsStateWithSnapshots
): IDeveloperField[] => {
  const snapshot = state.snapshots.get("INIT")!;

  return snapshot.fieldOrder.map(
    (fieldKey) => snapshot.fieldSpecs.get(fieldKey)!
  );
};
