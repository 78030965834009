import { z } from "zod";
import {
  DEFAULT_API_URL,
  DEFAULT_MAX_UNIQUE_VALUES_SELECT_FIELD,
  MAX_MAX_UNIQUE_VALUES_SELECT_FIELD,
  MAX_UPLOAD_FILE_SIZE,
} from "../../constants/constants";
import { EBackendSyncMode, EInvalidDataBehavior } from "../../interfaces";
import { StyleOverridesSchema } from "./style_overrides";

const isValidHttpUrl = (url: string): boolean => {
  let urlObj: URL;
  try {
    urlObj = new URL(url);
  } catch (_) {
    return false;
  }

  const goodProtocol =
    urlObj.protocol === "http:" || urlObj.protocol === "https:";
  const notLocalhost = urlObj.hostname !== "localhost";
  const notLoopback = !urlObj.host.startsWith("127.");

  return goodProtocol && notLocalhost && notLoopback;
};

export const LocaleSchema = z.enum([
  "bn",
  "en",
  "hr",
  "ja",
  "nl",
  "ru",
  "uk",
  "cs",
  "es",
  "hu",
  "ko",
  "no",
  "sv",
  "vi",
  "da",
  "fi",
  "id",
  "lt",
  "pl",
  "sw",
  "zh_CN",
  "de",
  "fr",
  "is",
  "lv",
  "pt",
  "th",
  "zh_TW",
  "el",
  "hi",
  "it",
  "ms",
  "ro",
  "tr",
]);

export type TLocaleShorthand = z.infer<typeof LocaleSchema>;

export const DEFAULT_LOCALE: TLocaleShorthand = "en";

const invalidDataBehaviorEnum = [
  "BLOCK_SUBMIT",
  "INCLUDE_INVALID_ROWS",
  "REMOVE_INVALID_ROWS",
] as const;

const backendSyncModeEnum = ["DISABLED", "FULL_DATA", "MAPPINGS_ONLY"] as const;

export const DeveloperSettingsSchema = z
  .object({
    importIdentifier: z.string(),
    title: z.string().optional(),
    allowInvalidSubmit: z.boolean().optional(),
    invalidDataBehavior: z.enum(invalidDataBehaviorEnum).optional(),
    allowEmptySubmit: z.boolean().default(true),
    backendSync: z.boolean().optional(),
    backendSyncMode: z.enum(backendSyncModeEnum).optional(),
    manualInputDisabled: z.boolean().optional(),
    manualInputOnly: z.boolean().default(false),
    allowCustomFields: z.boolean().default(false),
    passThroughUnmappedColumns: z.boolean().default(false),
    maxRecords: z.number().int().nullable().default(null),
    developmentMode: z.boolean().optional(),
    displayEncoding: z.boolean().default(true),
    styleOverrides: StyleOverridesSchema.default({}),
    maxFileSize: z
      .number()
      .int()
      .positive()
      .max(MAX_UPLOAD_FILE_SIZE)
      .default(MAX_UPLOAD_FILE_SIZE),
    webhookUrl: z
      .string()
      .nullable()
      .default(null)
      .refine(
        (val) => {
          if (val === null) return true;
          return isValidHttpUrl(val);
        },
        {
          message: "Invalid URL",
        }
      ),
    needsReviewWebhookUrl: z
      .string()
      .nullable()
      .default(null)
      .refine(
        (val) => {
          if (val === null) return true;
          return isValidHttpUrl(val);
        },
        {
          message: "Invalid URL",
        }
      ),
    initialData: z
      .union([z.array(z.record(z.any())), z.array(z.array(z.any()))])
      .nullable()
      .default(null),
    initialFile: z
      // This is a hack for the headless lambda where WebAPI stuff is not defined
      .instanceof(globalThis.File ?? {})
      .nullable()
      .default(null),
    uploadStep: z
      .object({
        helpText: z.string().nullable().default(null),
        sheetOverride: z.string().nullable().default(null),
      })
      .default({}),
    matchingStep: z
      .object({
        helpText: z.string().nullable().default(null),
        headerRowOverride: z.number().int().nullable().default(null),
        fuzzyMatchHeaders: z.boolean().default(true),
        matchToSchema: z.boolean().default(false),
      })
      .default({}),
    matchValuesStep: z
      .object({
        helpText: z.string().nullable().default(null),
        maxMappableSelectValues: z
          .number()
          .int()
          .gt(0)
          .lte(MAX_MAX_UNIQUE_VALUES_SELECT_FIELD)
          .default(DEFAULT_MAX_UNIQUE_VALUES_SELECT_FIELD),
      })
      .default({}),
    reviewStep: z
      .object({
        helpText: z.string().nullable().default(null),
        processingText: z.string().nullable().default(null),
        enableUserTransformations: z.boolean().default(false),
        allowAddingRows: z.boolean().default(true),
        allowRemovingRows: z.boolean().default(true),
        highlightAutoFixes: z.boolean().default(false),
      })
      .default({}),
    autoMapHeaders: z.boolean().default(false),
    delimiter: z.string().optional(),
    backendOverride: z
      .object({
        url: z.string(),
        type: z.enum(["AWS", "AZURE"]),
      })
      .default({
        url: DEFAULT_API_URL,
        type: "AWS",
      }),
    locale: LocaleSchema.default(DEFAULT_LOCALE),
    templateDownloadFilename: z.string().nullable().default(null),
    browserExcelParsing: z.boolean().default(false),
    version: z.string().default(""),
    alternateDomain: z.string().nullable().default(null),
  })
  .refine(
    (settings) => {
      if (settings.manualInputDisabled && settings.manualInputOnly) {
        return false;
      }
      return true;
    },
    { message: "manualInputDisabled and manualInputOnly can't both be `true`" }
  )
  .refine(
    (settings) => {
      if (
        !settings.reviewStep.allowAddingRows &&
        settings.manualInputDisabled === false
      ) {
        return false;
      }
      return true;
    },
    {
      message:
        "manualInputDisabled must not be `false` if reviewStep -> allowAddingRows is `false`.",
    }
  )
  .refine(
    (settings) => {
      if (
        !settings.reviewStep.allowAddingRows &&
        settings.manualInputOnly === true
      ) {
        return false;
      }
      return true;
    },
    {
      message:
        "manualInputOnly must not be `true` if reviewStep -> allowAddingRows is `false`.",
    }
  )

  .transform((settings) => {
    if (!settings.invalidDataBehavior) {
      settings.invalidDataBehavior = settings.allowInvalidSubmit
        ? EInvalidDataBehavior.INCLUDE_INVALID_ROWS
        : EInvalidDataBehavior.REMOVE_INVALID_ROWS;
    }

    // backendSyncMode obsoletes backendSync
    // if backendSync is true, we'll use that to not break existing workflows
    // but only if we don't have a value for backendSyncMode
    if (!settings.backendSyncMode) {
      settings.backendSyncMode = settings.backendSync
        ? EBackendSyncMode.FULL_DATA
        : EBackendSyncMode.DISABLED;
    }

    if (settings.manualInputDisabled === undefined) {
      settings.manualInputDisabled = !settings.reviewStep.allowAddingRows;
    }

    return settings;
  })
  .transform((settings) => {
    // If we have a backendOverride url that's different from the default, use that
    // Otherwise use the alternateDomain if present
    const { backendOverride, alternateDomain } = settings;

    if (backendOverride.url === DEFAULT_API_URL && alternateDomain) {
      const urlObj = new URL(alternateDomain);
      backendOverride.url = urlObj.protocol + "//app." + urlObj.host;
    }

    return settings;
  });

export type IDeveloperSettings = z.input<typeof DeveloperSettingsSchema>;
export type IDeveloperSettingsStrict = z.output<typeof DeveloperSettingsSchema>;
