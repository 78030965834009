import React from "react";
import { twMerge } from "tailwind-merge";

import { useTranslation } from "react-i18next";
import { ReactComponent as SparklesIcon } from "../../assets/sparkles.svg";
import { ReactComponent as CheckIcon } from "../../assets/check.svg";
import { ReactComponent as HideIcon } from "../../assets/hide.svg";
import { MATCH_TYPES } from "../../interfaces";

type Themes = "green" | "gray" | "blue";

interface IPill {
  type: MATCH_TYPES;
  theme?: Themes;
  alwaysOpen?: boolean;
}

const MAP_ICONS: {
  [key in MATCH_TYPES]: JSX.Element;
} = {
  [MATCH_TYPES.AI_SUGGESTED]: <SparklesIcon width={16} height={16} />,
  [MATCH_TYPES.AUTO_MATCHED]: <CheckIcon />,
  [MATCH_TYPES.USER_MATCHED]: <CheckIcon />,
  [MATCH_TYPES.UNMATCHED]: <CheckIcon />,
  [MATCH_TYPES.IGNORED]: <HideIcon />,
};

const themeClasses: Record<Themes, string> = {
  green: "bg-green-50 text-green-700",
  gray: "bg-ice-100 text-ice-500",
  blue: "bg-blue-50/50 text-blue-500",
};

export const Pill = ({ type, theme, alwaysOpen }: IPill) => {
  const { t } = useTranslation();

  return (
    <span
      className={twMerge(
        "text-xs font-medium rounded-full p-1 flex items-center gap-[2px] ",
        themeClasses[theme || "gray"],
        !alwaysOpen &&
          "group max-h-[24px] max-w-[24px] hover:max-w-sm transition-all overflow-hidden"
      )}
    >
      <span>{MAP_ICONS[type]}</span>
      <span
        className={twMerge(
          "transition-all delay-500 opacity-0 group-hover:opacity-100 hidden group-hover:block whitespace-nowrap leading-[normal]",
          alwaysOpen && "opacity-100 block"
        )}
      >
        {t(type)}
      </span>
    </span>
  );
};

export const getMatchPill = (matchType: MATCH_TYPES, open = false) => {
  switch (matchType) {
    case MATCH_TYPES.AI_SUGGESTED:
      return <Pill type={matchType} theme="blue" alwaysOpen={open} />;
    case MATCH_TYPES.AUTO_MATCHED:
    case MATCH_TYPES.USER_MATCHED:
      return <Pill type={matchType} theme="green" alwaysOpen={open} />;
    case MATCH_TYPES.IGNORED:
      return <Pill type={matchType} theme="gray" alwaysOpen={open} />;
    default:
      return null;
  }
};
